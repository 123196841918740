export default {
    "app.label.company": "Bon.D.Bara",
    "app.label.for": "pour",
    "app.label.en": "Anglais",
    "app.label.fr": "Francais",
    "app.label.ht": "Creole",
    "app.label.sp": "Espagnol",
    "app.label.home": "Acceuil",
    "app.label.form.title": "Formulaire de demande de test COVID-19 pour les voyageurs",
    "app.label.terme.title": "Termes et conditions",

    "app.label.term1": "Zanmi Lasante se décharge de la décision que prendront les autorités et agences de voyages à l'issue de votre test. ",
    "app.label.term2": "Veuillez consulter votre email et vos sms pour la confirmation de votre requête et de votre rendez-vous pour le test. ",
    "app.label.term3": "Vous effectuez votre paiement après le message de confirmation de Zanmi Lasante ",
    "app.label.term4": "Le test de Covid-19 vous coutera  la somme de 4000 gourdes  ",
    "app.label.term5": "Les numéros de comptes bancaires de ZL sont:<br></br> 1) <b>HTG: 425-1921-1655-515</b><br></br>  2) <b>USD: 425-1922-1655-523</b><br></br>   Le numéro de Moncash est:  <b> (509) 4891-6120</b><br></br> Vous pouvez soumettre une facture sur l'application de Moncash aussi. Si vous avez payé directement sur le compte de Zanmi Lasante, vous devez téléverser une copie du reçu de dépoté  ",
    "app.label.term6": "Aucune tiers personne et aucun autre numéro n'est autorisé à recevoir de l'argent sous le compte de Zanmi Lasante pour les tests de COVID-19.",
    "app.label.term7": "Si vous payez chez un marchand, conserver le message et noter quelque part le numéro de Txn ID : pour votre confirmation de paiement le jour du prélèvement, mais on préfère le paiement sur l'application mobile de Moncash. ",
    "app.label.term8": "Venez avec la copie du dépôt , le jour du rendez-vous , si vous avez fait un dépôt.  ",
    "app.label.term9": " Au cas où une autre personne paie pour vous, veuillez indiquer son nom et le numéro de téléphone par lequel la transaction a été effectuée. * En cliquant sur le bouton envoyer vous  agréez à nos termes et conditions. ",


    "app.label.nationality": "Nationalité",
    "app.label.departure_date": "Date de départ",
    "app.label.firstname": "Nom",
    "app.label.lastname": "Prénom",
    "app.label.gender": "Sexe",
    "app.label.birthdate": "Date de Naissance",
    "app.label.male": "Masculin",
    "app.label.female": "Féminin",
    "app.label.age": "Age (Années)",
    "app.label.month": "Mois (pour les < 1 an)",
    "app.label.phone": "Téléphone",
    "app.label.dest_contry": "Pays de destination",
    "app.label.address": "Adresse en Haïti",
    "app.label.hassymptom": "Avez des symptômes suggestifs de la COVID-19 ?",
    "app.label.yes": "Oui",
    "app.label.no": "Non",
    "app.label.cin": "CIN/NIF",
    "app.label.required": "Ce champs est obligatoire",
    "app.label.connexionfailled": "Vous avez pas de connexion internet",
    "app.label.connexionsuccess": "la connexion est rétablie",
    "app.label.previous": "Précédente",
    "app.label.next": "Suivant",
    "app.label.personalInformation": "Informations personnelles",
    "app.label.paymentmode": "Méthode de paiement",
    "app.label.email": "E-mail",
    "app.label.site": "Quel est le site de votre choix pour le prélèvement ?",
    "app.label.other": "Autre",
    "app.label.submit": "Soumettre",
    "app.label.depo": "dépôt",
    "app.label.moncash": "Numéro de transaction",
    "app.label.result": "Comment souhaitez-vous recevoir votre résultat ?",
    "app.label.bymail": "Par e-mail",
    "app.label.onsite": "À partir du centre de prélèvement",
    "app.label.upload": "Téléverser la fiche de dépôt",
    "app.label.confirm": "Votre demande est effectuée avec succès. un mail de confirmation vous a été envoyé",
    "app.label.invalid": "Format invalide",
    "app.label.NIF": "NIF",
    "app.label.CIN": "CIN",
    "app.label.greadOld": "Date ne peut etre superieur à aujourd'hui",
    "app.label.lowerOld": "Date ne peut etre inferieure à",
    "app.label.departureErrordate": "Il faut choisir une date  qui commence à partir de demain",
    "app.label.departureFailled": "La date de rendez-vous  doit être inférieure ou égale à 48 heures du date de départ",
    "app.label.ag_departureFailled": "Pour le test AG, la date de rendez-vous  doit être inférieure ou égale à 12 heures du date de départ",
    "app.label.pcr_departureFailled": "Pour le test PCR, la date de rendez-vous  doit être inférieure ou égale à 72 heures du date de départ",
    "app.label.departureFailled1": "Pour toutes questions ou informations, n' hésitez pas à nous appeler",
    "app.label.departureFailled2": "+(509) 2226-1006",
    "app.label.monthError": "le nombre de mois doit être  < 12",
    "app.label.p_birthdate": "JJ/MM/YYYY",
    "app.label.request": "Demande",
    "app.label.faqs": "Faqs et Aide",
    "app.label.invalidDate": "Date invalide",

    "app.label.modalTitle": "GUIDE D’INFORMATIONS A L’INTENTION DES VOYAGEURS POUR L’OBTENTION D’UN TEST COVID 19",
    "app.label.modalSubTitle": "Pour faciliter le processus d’obtention de tests et limiter les files d’attente, veuillez suivre les différentes étapes décrites ci-dessous :",

    "app.label.q1": "Comment Prendre un rendez-vous ?",
    "app.label.q2": "Comment payer un test ?",
    "app.label.q3": "Comment trouver les sites de prélèvements ?",
    "app.label.q4": "Comment trouver les résultats ?",

    "app.label.t1": "Formulaire d’enregistrement",
    "app.label.t2": "Paiement",
    "app.label.t3": "Prélèvements",
    "app.label.t4": "Résultats",

    "app.label.info1": '<p class="text-muted">Remplissez le formulaire de demande d’un test PCR ou antigénique* sur le site web de Zanmi Lasante à: www.covid.zanmilasante.org/request ou  à l’adresse suivante :  <a href="/request">https://covid.zanmilasante.org/request</a> </p>',
    "app.label.info2": '<p class="text-muted">Une fois le formulaire rempli, il est conseillé de procéder immédiatement au paiement pour confirmer son rendez-vous. Celui-ci s’annulera 12 heures avant l’heure prévue du prélèvement s’il n’est pas payé.  </p> <p>Le paiement s’effectuera préférentiellement par mon cash mais aussi par virement bancaire. (Voir plus bas) :</p> <p>Numéro de Mon Cash: <b>(509) 4891-6120</b></p>  <p>Informations Bancaires : <br></br><b>HTG: 425-1921-1655-515</b><br></br><b>USD: 425-1922-1655-523</b><br></br></p> <p class="text-muted"> Le tarif du PCR est de $ 75 us ou 6000gdes et celui du test antigénique de $ 50us ou 4000gdes. Les montants en gourdes sont sujets à changement inhérent aux fluctuations du marché des changes. </p>',
    "app.label.info3": ' <p class="text-muted">Les prélèvements pour les tests PCR se feront entre 8ham et 12hpm et les tests antigéniques entre 8ham et 3hpm. </p>  <p>Les sites de prélèvements sont :</p><ul class="verti-timeline list-unstyled" style={{width: "100%"}}>  <li class="event-list">   <h5>Hôpital de Mirebalais </h5>  <p class="text-muted">Route Chatulée, Mirebalais, Centre, HT5210, </p>  </li> <li class="event-list">   <h5>Espace Chatelain Tours </h5>  <p class="text-muted"># 42, Route de l’aéroport, Tabarre  </p>  </li> </ul> </p>',
    "app.label.info4": ' <p class="text-muted">Les résultats seront délivrés tous les jours aux sites de prélèvement. Les résultats des tests antigéniques seront délivrés le jour du prélèvement alors que les PCR seront remis 48h à 72h après le prélèvement. </p>'

    , "app.label.passport": "No. Passeport",
    "app.label.test_type": "Type de test",
    "app.label.contact": "Contact",
    "app.label.fullname": "Nom complet",
    "app.label.message": "Message",
    "app.label.appointmentHour": "Heure de rendez-vous",
    "app.label.appointmentDate": "Date de rendez-vous",
    "app.label.depot": "Virement bancaire",

    "app.label.MONCASH": "MONCASH",
    "app.label.DEPOSIT": "DÉPOT BANCAIRE",
    "app.label.CASH": "CASH",
    "app.label.free": "GRATUIT",
    "app.label.payment_type": "Modalité de Paiement",
    "app.label.field": "Ce champs",
    "app.label.field_required": "est obligatoire",
    "app.label.wait": "patientez s'il vous plait...",
};

