/**
 * Created by louidorpc on 1/20/21.
 */
import React from "react"
import {FormattedMessage} from "react-intl";
import {getIntlMsg} from "../../../constants/utils";

export class FileInput extends React.Component {

    state = {
        filename: ""
    }

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.split("base64,")[1]);
            reader.onerror = error => reject(error);
        });
    }

    onFileChange = async(e) => {
        const {input} = this.props
        const targetFile = e.target.files[0]
        if (targetFile) {
            const val = await this.getBase64(targetFile)
            const fileName = targetFile.name;
            this.setState({filename: fileName})
            const current = {
                'size': targetFile.size,
                'fileName': fileName.substring(0, fileName.lastIndexOf('.')),
                'fileType': fileName.substring(fileName.lastIndexOf('.') + 1),
                'uploadedFile': val
            }

            input.onChange(current)
        } else {
            input.onChange(null)
        }
    }

    render() {

        return (
            <>
            <label for="files" class="btn">
                <div class="dz-message needsclick">
                    <div class="mb-3">
                        <i class="display-4 text-muted uil uil-cloud-upload"></i>
                    </div>

                    <h6><FormattedMessage id="app.label.upload"/><span class="text-warning"> * </span></h6>
                    <span>{this.state.filename}</span>
                </div>
            </label>
            <input
                type="file"
                onChange={this.onFileChange}
                id="files"
                style={{visibility: "hidden"}}
            />
            </>
        )
    }
}