export const app = {
    name: `ECHO EARTH`,
    initial: `EA`,
    copyright: `app.label.copyright`,
    version: `1.0.0`,
    year: `2020`,
    adminBase: `admin`,
    defaultRootUrl: `${window.BASE_URL}` ? `${window.BASE_URL}` : `http://localhost:4000/api/rtr`,
};


export const assetsBasePath = {
    logos: `/assets/media/logos`,
    users: `/assets/media/users`
};


export default app;








