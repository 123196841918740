/**
 * Created by louidorpc on 1/26/21.
 */
import * as React from "react";
import useDocumentTitle from "../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../constants/utils";
import {injectIntl} from "react-intl";
import {Terms} from '../../../constants/options/data';
import actions from "../../../thunks/common/coreThunks";
import {useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {RequestModel, Applicant} from '../../../constants/models'
import swal from 'sweetalert'
import {useParams, withRouter} from "react-router-dom";
import {useEffect} from "react";
import ReactPlayer from 'react-player/lazy'

const HomePage = injectIntl(({intl}) => {
    const {module} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.home`));
    const dispatch = useDispatch();


    const criteria = {options: ['specimencenters']}

    useEffect(() => {
        dispatch(actions.loadItems(criteria));
    }, [dispatch]);


    const handleSubmiteSucce = (values) => {

        swal({
            title: `${values.firstName ? values.lastName : null}, ${getIntlMsg(intl, 'app.label.confirm')}`,
            text: "",
            icon: "success",
            button: "Next",
        }).then((value) => {
            document.location = "/";
        });
    }

    const [formData, setFormData] = useState({});

    const handleSubmite = values => {
        const ent = new RequestModel(values)

        const entity = {
            options: ['REQUESTS'], action: 'save', intl: intl, item: ent
        }
        setFormData(values);
        dispatch(actions.saveOrUpdateItem(entity));
    }

    const {loading, item, specimentcenters} = useSelector(state => ({
        loading: state.pagesReducer.requests.loadingItem,
        item: state.pagesReducer.requests.item,
        specimentcenters: state.pagesReducer.specimencenters.items,
    }));

    if (item && item.id != null) handleSubmiteSucce(formData)

    return (
        <React.Fragment>
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">


                            <div class="row mt-5">
                                <div class="col-xl-3 col-sm-5 mx-auto">
                                    <div>
                                        <img src="assets/images/faqs-img.png" alt="" class="img-fluid mx-auto d-block"/>
                                    </div>
                                </div>

                                <div class="col-xl-8">
                                    <div id="faqs-accordion" class="custom-accordion mt-5 mt-xl-0">
                                        <div class="card border shadow-none">
                                            <a href="#faqs-gen-ques-collapse" class="text-dark" data-toggle="collapse"
                                               aria-expanded="true" aria-controls="faqs-gen-ques-collapse">
                                                <div class="bg-light p-3">

                                                    <div class="media align-items-center">
                                                        <div class="mr-3">
                                                            <div class="avatar-xs">
                                                                <div class="avatar-title rounded-circle font-size-22">
                                                                    <i class="fa fa-money-bill-alt"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="media-body overflow-hidden">
                                                            <h5 class="font-size-16 mb-1">Tarif Test</h5>
                                                            <p class="text-muted text-truncate mb-0">Tarif Test</p>
                                                        </div>
                                                        <i class="fa fa-question font-size-16"></i>
                                                    </div>

                                                </div>
                                            </a>

                                            <div id="faqs-gen-ques-collapse" class="collapse show"
                                                 data-parent="#faqs-accordion">
                                                <div class="p-4">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div>
                                                                <div class="media mt-4">
                                                                    <div class="avatar-xs mr-3">
                                                                        <div
                                                                            class="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                                                            <i class="uil uil-question-circle"></i>
                                                                        </div>
                                                                    </div>

                                                                    <div class="media-body">
                                                                        <h5 class="font-size-16 mt-1">Comment payer par Moncash?</h5>
                                                                        <p class="text-muted">...</p>
                                                                    </div>
                                                                </div>

                                                                <div class="media mt-4">
                                                                    <div class="avatar-xs mr-3">
                                                                        <div
                                                                            class="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                                                            <i class="uil uil-question-circle"></i>
                                                                        </div>
                                                                    </div>

                                                                    <div class="media-body">
                                                                        <h5 class="font-size-16 mt-1">Comment payer par Depot ?</h5>
                                                                        <p class="text-muted">.....</p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div class="col-md-6">
                                                            <div>
                                                                <div class="media mt-4">
                                                                    <div class="avatar-xs mr-3">
                                                                        <div
                                                                            class="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                                                            <i class="uil uil-question-circle"></i>
                                                                        </div>
                                                                    </div>

                                                                    <div class="media-body">
                                                                        <h5 class="font-size-16 mt-1">Quel est le prix du Test
                                                                            ?</h5>
                                                                        <p class="text-muted">.... </p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div class="col-md-6">
                                                            <div>
                                                                <div class="media mt-4">
                                                                    <div class="avatar-xs mr-3">
                                                                        <div
                                                                            class="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                                                            <i class="uil uil-question-circle"></i>
                                                                        </div>
                                                                    </div>

                                                                    <div class="media-body">
                                                                        <h5 class="font-size-16 mt-1">Peut-on rembousser pour une rendez-vous rate
                                                                            ?</h5>
                                                                        <p class="text-muted">.... </p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card border shadow-none">
                                            <a href="#faqs-privacy-policy-collapse" class="text-dark collapsed"
                                               data-toggle="collapse" aria-expanded="false"
                                               aria-controls="faqs-privacy-policy-collapse">
                                                <div class="bg-light p-3">

                                                    <div class="media align-items-center">
                                                        <div class="mr-3">
                                                            <div class="avatar-xs">
                                                                <div class="avatar-title rounded-circle font-size-22">
                                                                    <i class="fa fa-syringe"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="media-body overflow-hidden">
                                                            <h5 class="font-size-16 mb-1">Prelevement</h5>
                                                            <p class="text-muted text-truncate mb-0">prelevement</p>
                                                        </div>
                                                        <i class="fa fa-question font-size-16"></i>
                                                    </div>

                                                </div>
                                            </a>

                                            <div id="faqs-privacy-policy-collapse" class="collapse"
                                                 data-parent="#faqs-accordion">
                                                <div class="p-4">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div>
                                                                <div class="media mt-4">
                                                                    <div class="avatar-xs mr-3">
                                                                        <div
                                                                            class="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                                                            <i class="uil uil-question-circle"></i>
                                                                        </div>
                                                                    </div>

                                                                    <div class="media-body">
                                                                        <h5 class="font-size-16 mt-1">Comment Contacter le CP ?</h5>
                                                                        <p class="text-muted">...</p>
                                                                    </div>
                                                                </div>



                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card border shadow-none">
                                            <a href="#faqs-pricing-plans-collapse" class="text-dark collapsed"
                                               data-toggle="collapse"
                                               aria-expanded="false" aria-controls="faqs-pricing-plans-collapse">
                                                <div class="bg-light p-3">

                                                    <div class="media align-items-center">
                                                        <div class="mr-3">
                                                            <div class="avatar-xs">
                                                                <div class="avatar-title rounded-circle font-size-22">
                                                                    <i class="fa fa-poll"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="media-body overflow-hidden">
                                                            <h5 class="font-size-16 mb-1">Resultat</h5>
                                                            <p class="text-muted text-truncate mb-0">Resultat</p>
                                                        </div>
                                                        <i class="fa fa-question font-size-16"></i>
                                                    </div>

                                                </div>
                                            </a>

                                            <div id="faqs-pricing-plans-collapse" class="collapse"
                                                 data-parent="#faqs-accordion">
                                                <div class="p-4">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div>
                                                                <div class="media mt-4">
                                                                    <div class="avatar-xs mr-3">
                                                                        <div
                                                                            class="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                                                            <i class="uil uil-question-circle"></i>
                                                                        </div>
                                                                    </div>

                                                                    <div class="media-body">
                                                                        <h5 class="font-size-16 mt-1">Est-ce que je peux avoir mes resultats par mail ?</h5>
                                                                        <p class="text-muted">....</p>
                                                                    </div>
                                                                </div>



                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
    });

    export default React.memo(HomePage)

