import * as React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import {ToastContainer} from "react-toastify";
import configureStore, {history} from "./redux/store/index";
import initialState from "./constants/utils/initialState";
import Layout from "./components";


const store = configureStore(initialState);


const app = (

    <Provider store={store}>
        <ToastContainer autoClose={2000} position={"top-center"}/>
        <Layout history={history}/>
    </Provider>
);

ReactDOM.render(app, document.getElementById("layout-wrapper"));


