import * as React from "react";
import {useParams, withRouter} from "react-router";
import pages from "../../constants/options/_pages";
import ActualityPage from "./home";
import Contact from "./contact";
import RequestPage from "./request";
import GlobalHomePage from "./faqs";


export const PageManager = () => {
    const {module} = useParams();
    return _onLoadPage(module)
}


function _onLoadPage(module) {
    switch (module) {
        case pages.REQUESTS:
            return <RequestPage/>
        case pages.FAQS:
            return <ActualityPage/>
        case pages.CONTACT:
            return <Contact/>
        default: {
            return <GlobalHomePage/>
        }
    }
}


export default React.memo(withRouter(PageManager));
