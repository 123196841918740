import React from "react";
import {FormattedMessage} from "react-intl";
import {Field, reduxForm} from "redux-form";
import moment from 'moment'
import actions from "../../../../thunks/common/coreThunks";
import {connect, useDispatch, useSelector} from 'react-redux'
import {
    validate,
    normalizeCin,
    normalizeNif,
    normalizePhone,
    maxLength,
    normalizeCinNif,
    minDateNormalize,
    valDob,
    valNif,
    valCin,
    departureDate,
    renderError,
    birthDate,
    email,
    maxLengthMob
} from "./validator";
import {required} from '../../../../constants/validators'
import {renderField} from "../../../common/utils/FormComponent";
import {getIntlMsg} from "../../../../constants/utils";
import {Country} from '../../../../constants/options/data';
import {reset, initialize, change} from 'redux-form'
import {useEffect, useState} from "react";
import {GoogleLogin} from 'react-google-login';
import swal from '@sweetalert/with-react'

const onClearForm = (reset, resetEntreprise, resetFile) => {
    reset('covid19');
    resetFile();
    resetEntreprise();
}

const Form = props => {
    const {handleSubmit, reset, pristine, submitting, specimentcenters, itemPrices, intl} = props;

    const dispatch = useDispatch()
    const [testTypes, setSelectedTestType] = useState([]);

    const handleSelectedTestType = e => {
        // dispatch(change('covid19', 'indentifierType', e));
        setSelectedTestType(e)
    }


    const handleSelectedSite = e => {

        dispatch(change('covid19', 'prelSite', e));
        // setSelected(e)
    }

    const unitFormatter = unit => value => value !== undefined ? moment(value).format('mm/dd/yyyy') : '';


    return (
        <React.Fragment>
            <form onSubmit={handleSubmit}>
                <div className="form-row m-2">


                    <div className="form-group floating-label show-label col-md-12">
                        <Field className="form-control" id="fullname" name="fullname" component={renderField}
                               validate={[required]} type="text" label="app.label.fullname"
                               placeholder="app.label.fullname"/>
                    </div>


                    <div className="form-group floating-label show-label col-md-6">
                        <Field className="form-control" id="phone" name="phone" component={renderField}
                               type="text" label="app.label.phone" placeholder="app.label.phone"
                               normalize={normalizePhone} validate={[required]}/>
                    </div>

                    <div className="form-group floating-label show-label col-md-6">
                        <Field
                            className="form-control"
                            id="inputEmail6"
                            name="email"
                            component={renderField}
                            validate={[email]}
                            type="text"
                            label="app.label.email"
                            placeholder="app.label.email"
                        />
                    </div>

                    <div className="form-group floating-label show-label col-md-12">
                        <Field
                            className="form-control"
                            id="inputEmail6"
                            name="passportNo"
                            component={renderField}
                            validate={[required]}
                            type="textarea"
                            label="app.label.message"
                            placeholder="app.label.message"
                        />
                    </div>


                </div>
                <button
                    className="btn btn-primary float-right"
                    type="submit" disabled={pristine || submitting}>
                    <FormattedMessage id="app.label.submit" defaultMessage="Next"/>
                </button>
            </form>

        </React.Fragment>

    );
}

const mapStateToProps = (state, props) => {
    return (
        {
            initialValues: {}

        })
}
const afterSubmit = (result, dispatch, props) => {
}

export default connect(
    mapStateToProps
)(reduxForm({
    form: 'contact',
    validate,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true

})(Form));

