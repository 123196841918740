/**
 * Created by louidorpc on 1/29/21.
 */
import React from 'react'
import {getIntlMsg} from "../../../constants/utils";

const ModalInfo = ({children, intl}) => (

    <div class="modal fade bs-example-modal-xl border-0" tabindex="-1" role="dialog"
         aria-labelledby="myExtraLargeModalLabel"
         aria-hidden="true" id="playModal">
        <div class="modal-dialog modal-xl border-0">
            <div class="modal-content border-0">
                <div class="modal-header">
                    <h5 class="modal-title mt-0"
                        id="myExtraLargeModalLabel">{getIntlMsg(intl, 'app.label.modalTitle')}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body border-0">
                    <p class="text-muted text-center font-size-16">{getIntlMsg(intl, 'app.label.modalSubTitle')}</p>
                    <div class="row mt-5">
                        <div class="col-xl-3 col-sm-5 mx-auto">
                            <div>
                                <img src="assets/images/faqs-img.png" alt="" class="img-fluid mx-auto d-block"/>
                            </div>
                        </div>

                        <div class="col-xl-8">
                            <div id="faqs-accordion" class="custom-accordion mt-5 mt-xl-0">
                                <div class="card border shadow-none">
                                    <a href="#faqs-gen-ques-collapse" class="text-dark" data-toggle="collapse"
                                       aria-expanded="true" aria-controls="faqs-gen-ques-collapse">
                                        <div class="bg-light p-3">

                                            <div class="media align-items-center">
                                                <div class="mr-3">
                                                    <div class="avatar-xs">
                                                        <div class="avatar-title rounded-circle font-size-22">
                                                            <i class="fa fa-calendar-check"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="media-body overflow-hidden">
                                                    <h5 class="font-size-16 mb-1">{getIntlMsg(intl, 'app.label.t1')}</h5>
                                                    <p class="text-muted text-truncate mb-0"></p>
                                                </div>
                                                <i class="fa fa-arrow-up accor-down-icon font-size-16"></i>
                                            </div>

                                        </div>
                                    </a>

                                    <div id="faqs-gen-ques-collapse" class="collapse show"
                                         data-parent="#faqs-accordion">
                                        <div class="p-4">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div>
                                                        <div class="media mt-4">
                                                            <div class="avatar-xs mr-3">
                                                                <div
                                                                    class="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                                                    <i class="uil uil-question-circle"></i>
                                                                </div>
                                                            </div>

                                                            <div class="media-body">
                                                                <h5 class="font-size-16 mt-1">{getIntlMsg(intl, 'app.label.q1')}</h5>
                                                                <div dangerouslySetInnerHTML={{
                                                                    __html: intl.formatMessage(
                                                                        {id: 'app.label.info1'},
                                                                        {value: '<strong>STRONG</strong>'}
                                                                    )
                                                                }} style={{textAlign: 'justify'}}></div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card border shadow-none">
                                    <a href="#faqs-privacy-policy-collapse1" class="text-dark"
                                       data-toggle="collapse" aria-expanded="true"
                                       aria-controls="faqs-privacy-policy-collapse">
                                        <div class="bg-light p-3">

                                            <div class="media align-items-center">
                                                <div class="mr-3">
                                                    <div class="avatar-xs">
                                                        <div class="avatar-title rounded-circle font-size-22">
                                                            <i class="fa fa-money-bill"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="media-body overflow-hidden">
                                                    <h5 class="font-size-16 mb-1">{getIntlMsg(intl, 'app.label.t2')}</h5>
                                                    <p class="text-muted text-truncate mb-0"></p>
                                                </div>
                                                <i class="fa fa-arrow-up accor-down-icon font-size-16"></i>
                                            </div>

                                        </div>
                                    </a>

                                    <div id="faqs-privacy-policy-collapse1" class="collapse show"
                                         data-parent="#faqs-accordion">
                                        <div class="p-4">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div>
                                                        <div class="media mt-4">
                                                            <div class="avatar-xs mr-3">
                                                                <div
                                                                    class="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                                                    <i class="uil uil-question-circle"></i>
                                                                </div>
                                                            </div>

                                                            <div class="media-body">
                                                                <h5 class="font-size-16 mt-1">{getIntlMsg(intl, 'app.label.q2')}</h5>

                                                                <div dangerouslySetInnerHTML={{
                                                                    __html: intl.formatMessage({id: 'app.label.info2'}, {value: '<strong>STRONG</strong>'})
                                                                }} style={{}}></div>

                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card border shadow-none">
                                    <a href="#faqs-privacy-policy-collapse2" class="text-dark collapsed"
                                       data-toggle="collapse" aria-expanded="false"
                                       aria-controls="faqs-privacy-policy-collapse">
                                        <div class="bg-light p-3">

                                            <div class="media align-items-center">
                                                <div class="mr-3">
                                                    <div class="avatar-xs">
                                                        <div class="avatar-title rounded-circle font-size-22">
                                                            <i class="fa fa-syringe"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="media-body overflow-hidden">
                                                    <h5 class="font-size-16 mb-1">{getIntlMsg(intl, 'app.label.t3')}</h5>
                                                    <p class="text-muted text-truncate mb-0"></p>
                                                </div>
                                                <i class="fa fa-arrow-up accor-down-icon font-size-16"></i>
                                            </div>

                                        </div>
                                    </a>

                                    <div id="faqs-privacy-policy-collapse2" class="collapse"
                                         data-parent="#faqs-accordion">
                                        <div class="p-4">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div>
                                                        <div class="media mt-4">
                                                            <div class="avatar-xs mr-3">
                                                                <div
                                                                    class="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                                                    <i class="uil uil-question-circle"></i>
                                                                </div>
                                                            </div>

                                                            <div class="media-body">
                                                                <h5 class="font-size-16 mt-1">{getIntlMsg(intl, 'app.label.q3')}</h5>
                                                                <div dangerouslySetInnerHTML={{
                                                                    __html: intl.formatMessage(
                                                                        {id: 'app.label.info3'},
                                                                        {value: '<strong>STRONG</strong>'}
                                                                    )
                                                                }} style={{textAlign: 'justify'}}></div>

                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card border shadow-none">
                                    <a href="#faqs-pricing-plans-collapse3" class="text-dark collapsed"
                                       data-toggle="collapse"
                                       aria-expanded="false" aria-controls="faqs-pricing-plans-collapse">
                                        <div class="bg-light p-3">

                                            <div class="media align-items-center">
                                                <div class="mr-3">
                                                    <div class="avatar-xs">
                                                        <div class="avatar-title rounded-circle font-size-22">
                                                            <i class="fa fa-poll"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="media-body overflow-hidden">
                                                    <h5 class="font-size-16 mb-1">{getIntlMsg(intl, 'app.label.t4')}</h5>
                                                    <p class="text-muted text-truncate mb-0"></p>
                                                </div>
                                                <i class="fa fa-arrow-up accor-down-icon font-size-16"></i>
                                            </div>

                                        </div>
                                    </a>

                                    <div id="faqs-pricing-plans-collapse3" class="collapse"
                                         data-parent="#faqs-accordion">
                                        <div class="p-4">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div>
                                                        <div class="media mt-4">
                                                            <div class="avatar-xs mr-3">
                                                                <div
                                                                    class="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                                                    <i class="uil uil-question-circle"></i>
                                                                </div>
                                                            </div>

                                                            <div class="media-body">
                                                                <h5 class="font-size-16 mt-1">{getIntlMsg(intl, 'app.label.q1')}</h5>
                                                                <div dangerouslySetInnerHTML={{
                                                                    __html: intl.formatMessage(
                                                                        {id: 'app.label.info4'},
                                                                        {value: '<strong>STRONG</strong>'}
                                                                    )
                                                                }} style={{textAlign: 'justify'}}></div>

                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)
export default ModalInfo;