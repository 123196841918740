export default {
    "app.label.en": "Inglés",
    "app.label.fr": "Francais",
    "app.label.ht": "Criollo",
    "app.label.sp": "Español",
    "app.label.dashboard": "Tablo rezime",
    "app.label.home": "Pagina de inicio",


    "app.label.support": "Sipò",
    "app.label.track_your_orders": "Siveye kòmand ou yo!",

    "app.label.form.title": "Formulario de solicitud de prueba COVID-19 para viajeros",
    "app.label.terme.title": "Términos y Condiciones",

    "app.label.term1": "Zanmi Lasante no se hace responsable de la decisión que tomen las autoridades y agencias de viajes después de su prueba.",
    "app.label.term2": "Por favor revise su correo electrónico y sms para confirmar su solicitud y su cita de prueba. ",
    "app.label.term3": "Deberá pagar solo después del mensaje de confirmación de Zanmi lasante.",
    "app.label.term4": "Para tener acceso a la prueba COVID-19 debes pagar una suma de 4000 gourdes",
    "app.label.term5": "Los números de cuenta bancaria de ZL son: <br></br> 1) <b>HTG: 425-1921-1655-515</b><br></br>  2) <b>USD: 425-1922-1655-523</b><br></br>  El número de Moncash es: <b> (509) 4891-6120</b><br></br> También puede enviar una factura en la aplicación Moncash. Si ha pagado directamente a la cuenta de Zanmi Lasante, debe cargar una copia del recibo de depósito ",
    "app.label.term6": "Ningún tercero ni ningún otro número está autorizado a recibir dinero bajo la cuenta de Zanmi lasante para las pruebas COVID-19.",
    "app.label.term7": "Si paga en un comerciante, guarde el mensaje y anote el número de identificación de Txn en algún lugar: para la confirmación del pago el día del débito, pero preferimos el pago en la aplicación móvil moncash.",
    "app.label.term8": "venga con el cheque el día de la cita, si paga con cheque.",
    "app.label.term9": "En caso de que alguien más pague por usted, indique su nombre y el número de teléfono a través del cual se realizará la transacción.",
    "app.label.required": 'Este campo es requerido',


    "app.label.nationality": "Nacionalidad",
    "app.label.departure_date": "Fecha de salida",
    "app.label.firstname": "Apellido",
    "app.label.lastname": "Primer nombre",
    "app.label.gender": "Sexo",
    "app.label.birthdate": "Fecha de nacimiento",
    "app.label.male": "Masculino",
    "app.label.female": "Femenino",
    "app.label.age": "Edad",
    "app.label.month": "Meses (por <1 año)",
    "app.label.phone": "Teléfono",
    "app.label.dest_contry": "País de destino",
    "app.label.address": "Dirección en Haití",
    "app.label.hassymptom": "¿Tiene síntomas que sugieran COVID-19?",
    "app.label.yes": "Si",
    "app.label.no": "No",
    "app.label.cin": "CIN/NIF",
    "app.label.connexionfailled": "No tienes conexión a internet",
    "app.label.connexionsuccess": "La conexión se restablece",

    "app.label.previous": "Avan",
    "app.label.next": "Siguiente",
    "app.label.personalInformation": "Información personal",
    "app.label.paymentmode": "Método de pago",
    "app.label.email": "Email",
    "app.label.site": "¿Cuál es el sitio de su elección para la domiciliación bancaria?",
    "app.label.other": "Otro",
    "app.label.submit": "Enviar",
    "app.label.depo": "Depositar",
    "app.label.moncash": "Número de transacción",
    "app.label.result": "¿cómo le gustaría recibir su resultado?",

    "app.label.bymail": "Por email",
    "app.label.onsite": "Del centro de acopio",
    "app.label.upload": "Subir formulario de depósito",
    "app.label.confirm": "Tu solicitud es exitosa. se le ha enviado un correo electrónico de confirmación",
    "app.label.invalid": "Formato inválido",
    "app.label.NIF": "NIF",
    "app.label.CIN": "CIN",

    "app.label.greadOld": "La fecha no puede ser mayor que hoy",
    "app.label.lowerOld": "La fecha no puede ser menor que",
    "app.label.departureErrordate": "Tienes que elegir una fecha que comience a partir de mañana",


    "app.label.monthError": "El número de meses debe ser < 12",
    "app.label.p_birthdate": "JJ/MM/YYYY",
    "app.label.request": "Solicitud",
    "app.label.faqs": "Preguntas frecuentes y lista",
    "app.label.invalidDate": "Fecha invalida",

    "app.label.departureFailled": "La fecha de salida debe ser mayor o igual a 48 horas. ",
    "app.label.departureFailled1": "Para cualquier consulta o información, no dudes en llamarnos ",
    "app.label.departureFailled2": "+(509) 2226-1006",
    "app.label.modalTitle": "GUÍA DE INFORMACIÓN PARA VIAJEROS PARA OBTENER UNA PRUEBA COVID 19",
    "app.label.modalSubTitle": "Para facilitar el proceso de obtención de pruebas y reducir las colas, siga los distintos pasos que se describen a continuación:",

    "app.label.q1": "¿Cómo concertar una cita?",
    "app.label.q2": "¿Cómo pagar una prueba?",
    "app.label.q3": "¿Cómo encontrar los centros de acopio?",
    "app.label.q4": "¿Cómo encontrar los resultados?",

    "app.label.t1": "Formulario de inscripción",
    "app.label.t2": "Pago",
    "app.label.t3": "Especímenes",
    "app.label.t4": "Resultados",

    "app.label.info1": '<p class="text-muted">Complete el formulario de solicitud de prueba de PCR o antígeno * en el sitio web de Zanmi Lasante en: www.covid.zanmilasante.org/request o en la siguiente dirección: <a href="/request">https://covid.zanmilasante.org/request</a> </p>',
    "app.label.info2": '<p class="text-muted">Una vez completado el formulario, es recomendable proceder inmediatamente al pago para confirmar su cita. Esto se cancelará 12 horas antes de la hora programada del débito si no se paga.</p><p>El pago se realizará preferentemente en efectivo pero también mediante transferencia bancaria. (Vea abajo) :</p>  <p>Numero Mon Cash : <b>(509) 4891-6120</b></p>  <p>Información bancaria :  <br></br><b>HTG: 425-1921-1655-515</b><br></br><b>USD: 425-1922-1655-523</b><br></br></p> <p class="text-muted">El precio de la PCR es de $ 75us o 6000gdes y el de la prueba antigénica es de $ 50us o 4000gdes. Los montos en gourdes están sujetos a cambios inherentes a las fluctuaciones en el mercado cambiario.</p>',
    "app.label.info3": ' <p class="text-muted">Las muestras para las pruebas de PCR se realizarán entre las 8 a.m. y las 12 p.m. y las pruebas antigénicas entre las 8 a.m. y las 3 a.m.</p>  <p>Los sitios de las muestras son:: <ul class="verti-timeline list-unstyled">   <li class="event-list">   <h5>Hôpital de Mirebalais </h5>  <p class="text-muted">Route Chatulée, Mirebalais, Centre, HT5210, </p>  </li> <li class="event-list">   <h5>Espace Chatelain Tours </h5>  <p class="text-muted"># 42, Route de l’aéroport, Tabarre  </p>  </li>   </ul></p>',
    "app.label.info4": ' <p class="text-muted">Los resultados se entregarán diariamente a los sitios de muestreo. Los resultados de las pruebas antigénicas se entregarán el día de la muestra mientras que los PCR se entregarán 48 a 72 horas después de la muestra.</b></p>'

    ,
    "app.label.passport": "Pasaporte no.",
    "app.label.test_type": "Tipo de prueba",
    "app.label.contact": "Contactar ",
    "app.label.fullname": "nombre completo ",
    "app.label.message": "Mensaje ",
    "app.label.appointmentHour": "Hora de la cita  ",
    "app.label.appointmentDate": "Fecha de nombramiento",
    "app.label.depot": "Transferencia bancaria",
    "app.label.MONCASH": "MONCASH",
    "app.label.DEPOSIT": "DEPOSITO BANCARIO",
    "app.label.CASH": "CASH",
    "app.label.free": "LIBRE",
    "app.label.payment_Type": "Modalidad de pago",
    "app.label.field": "Este campo",
    "app.label.field_required": "es obligatorio",
    "app.label.wait": "cuelga porfavor...",
    "app.label.ag_departureFailled": "Para la prueba GA, la fecha de la cita debe ser menor o igual a 12 horas desde la fecha de salida",
    "app.label.pcr_departureFailled": "Para la prueba de PCR, la fecha de la cita debe ser menor o igual a 72 horas desde la fecha de salida",
};


