/**
 * Created by louidorpc on 1/19/21.
 */
import React from 'react'


export const required = (value) => (value || typeof value === 'number' ? undefined : 'required');


export const maxLength = (max) => (value) =>
    value && value.length > max ? `Must be ${max} characters or less` : undefined;
export const maxLength15 = maxLength(15);
export const minLength = (min) => (value) =>
    value && value.length < min ? `Must be ${min} characters or more` : undefined;
export const minLength2 = minLength(2);
export const number = (value) => (value && isNaN(Number(value)) ? 'Must be a number' : undefined);
export const minValue = (min) => (value) => (value && value < min ? `Must be at least ${min}` : undefined);
export const minValue13 = minValue(13);
export const email = (value) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email address' : undefined;
export const tooYoung = (value) => (value && value < 13 ? 'You do not meet the minimum age requirement!' : undefined);
export const aol = (value) =>
    value && /.+@aol\.com/.test(value) ? 'Really? You still use AOL for your email?' : value ? undefined : "Email is empty";
export const alphaNumeric = (value) =>
    value && /[^a-zA-Z0-9 ]/i.test(value) ? 'Only alphanumeric characters' : undefined;
export const phoneNumber = (value) =>
    value && !/^(0|[1-9][0-9]{7})$/i.test(value) ? 'Invalid phone number, must be 8 digits' : value ? undefined : "The phone is empty";

export const renderField = ({input, label, type, meta: {touched, error, warning}}) => (
    <div>
        <label>{label}</label>
        <div>
            <input className="form-control" {...input} placeholder={label} type={type}/>
            {touched && ((error && <span style={{color: 'red'}}>{error}</span>) || (warning &&
            <span style={{color: 'red'}}>{warning}</span>))}
        </div>
    </div>
);

export const renderSelectField = ({select, label, type, meta: {touched, error, warning}}) => (
    <div>
        <div>
            <input className="form-control" {...select} type={type}/>
            {touched && ((error && <span style={{color: 'red'}}>{error}</span>) || (warning && <span>{warning}</span>))}
        </div>
    </div>
);

export const birthDate = (value) => {
    if (value && (new Date(value) < new Date('1900'))) {
        return "Date can not be less than 1900";
    } else if (value && (new Date(value) > new Date())) {
        return "Date can not be greater than today";
    } else {
        if (value)
            return undefined
        else
            return "the birthdate is empty";
    }
};

export const haitianNif = (value) => {
    if (!value || (value && (value.match(/^(\d{3}-){3}\d$/g)))) {
    }
    else {
        return "NIf format is incorrect ex:(000-000-000-0)";
    }
    if (value)
        return undefined
    else  return "the NIF is empty ==>ex:(000-000-000-0";
};

export const gender = (value) => {
    console.log("---------------------", value)
    if (value === undefined || value === "Select a gender") {
        return "the gender is empty";
    }
    else {
        return undefined;
    }

};

export const departmentRequired = (value) => {
    console.log("---------------------", value)
    if (value === "Select a department") {
        return "the department is empty";
    }
    else {
    }

};

