/**
 * Created by louidorpc on 1/20/21.
 */
import {revertDate, formatDate} from "./utils";


export class RequestDTO {
    constructor(params) {
        const {
            firstName, lastName, gender, dob,
            phone, email,
            idType, identifier, address,
            paymentType, trxCode,
            departure, destination, symptoms, prelSite, uploadFile,
            byMail, onSite, testType, age, nationality, appointmentDate, appointmentHour
        } = params;

        this.applicant = {
            personalInfos: {
                firstName,
                lastName,
                gender,
                age,
                dob: revertDate(dob),
            },

            socialInfos: {
                phones: [{no: phone}],
                emails: [{current: email}],
                identifier: {type: "PASSPORT", value: identifier},
                address: {locality: "", no: address},
                nationality,
            }
        };
        this.payment = {paymentType, trxCode, file: uploadFile};

        this.specimenCenter = {id: prelSite};
        this.departure = formatDate(departure);
        this.destination = destination;
        this.symptoms = symptoms === 'yes';
        this.testType = {id: testType};
        this.appointmentDate = `${formatDate(appointmentDate)}T${appointmentHour}:00`;
        this.type = "TRAVELER";
    }


    // constructor(params) {
    //     if (params) {
    //         const {
    //             id, phoneId, emailId, addressId, identifierId, personalInfoId, socialInfoId, paymentId,
    //             firstName, lastName, nickname, gender, dob, age, codeLaboratory,
    //             phone, email, nationality, currentId, county, district, municipality, subMunicipality, address,
    //             paymentType, trxCode, testType, appointmentHour, appointmentDate,
    //             departure, destination, symptoms, specimenCenter, uploadFile,type
    //         } = params;
    //
    //         this.id = id;
    //         this.codeLaboratory = codeLaboratory
    //         this.applicant = {
    //
    //             personalInfos: {id: personalInfoId, firstName, lastName, nickname, gender, dob, age},
    //
    //             socialInfos: {
    //                 id: socialInfoId,
    //                 address: {
    //                     id: addressId,
    //                     locality: `${subMunicipality},${municipality},${district},${county}`,
    //                     no: address
    //                 },
    //                 phones: [{id: phoneId, no: phone}],
    //                 emails: [{id: emailId, current: email}],
    //                 identifier: {id: identifierId, type: 'PASSPORT', value: currentId},
    //                 nationality,
    //             }
    //         }
    //         this.payment = {id: paymentId, paymentType, trxCode, file: uploadFile};
    //         this.specimenCenter = {id: specimenCenter};
    //         this.departure = departure;
    //         this.destination = destination;
    //         this.symptoms = symptoms === 'yes';
    //         this.testType = {id: testType};
    //         this.appointmentDate = `${appointmentDate}T${appointmentHour}:00`;
    //         this.type = type
    //     }

}


export class ContactDTO {
    constructor(params) {
        const {
            fullname, email, phone, message,
        } = params;


        this.fullname = fullname
        this.email = email
        this.phone = phone
        this.message = message;
    }
}


export class RequestModel {

    Requestconstructor({
        address,
        age,
        byMail,
        onSite,
        prelSite,
        dob,
        symptoms,
        phone,
        gender,
        uploadFile,
        destination,
        departure,
        email,
        paymentType,
        txrCode,
        nationality,
        identifier,
        identifierType,
        firstName,
        lastName,
        maritalStatus
    }) {
        this.applicant = {
            address: {locality: '', no: address},
            age,
            dob: revertDate(dob),
            firstName,
            lastName,
            socialInfos: {
                maritalStatus,
                nationality,
                phones: [
                    {areaCode: '+509', no: phone}
                ],
                emails: [
                    {current: email}
                ],
                gender,
                identifier: {
                    type: identifierType,
                    value: identifier
                },
            }
        }

        this.payment = {
            paymentType: paymentType,
            payed: true,
            txrCode
        };
        this.departure = revertDate(departure);
        this.destination = destination;
        this.specimenCenter = {
            id: prelSite
        };
        this.symptoms = symptoms;
        this.uploadFile = uploadFile;
        this.resultPublishingType = byMail && onSite ? "BY_MAIL_ON_SITE" : byMail ? "BY_MAIL" : onSite ? "ON_SITE" : "BY_MAIL";


    }


}

export class Applicant {
    constructor(value) {
        this.applicant = new RequestModel(value)
    }
}
