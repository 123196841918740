export default {
    "app.label.en": "English",
    "app.label.fr": "French",
    "app.label.ht": "Creole",
    "app.label.sp": "Spanish",

    //Title
    "app.label.blog": "Blog",
    "app.label.medias": "Multimedias",
    "app.label.service_centers": "Centres de Service",
    "app.label.electrical_terminals": "Bornes Electriques",
    "app.label.contact_help": "Aides & Contact",
    "app.label.waste_declaration": "Déclaration Déchets",
    "app.label.stay_informed": "Restez informer",
    "app.label.download_our_app": "Téléchargez nos apps",
    "app.label.download_on_the": "Téléchargez sur",
    "app.label.app_store": "App Store",
    "app.label.google_play": "Google Play",
    "app.label.subscription_desc": `*Abonnez-vous à notre newsletter pour recevoir
                                     les dèrnières informations et nouveaux services`,

    "app.label.audios": "Bandes Audios",
    "app.label.videos": "Films",
    "app.label.hiv": "VIH",

    "app.label.home": "Home",
    "app.label.about": "About",

    "app.label.form.title": "COVID-19 test request form for travelers",

    "app.label.terme.title": "Terms and conditions",


    "app.label.term1": "Zanmi Lasante is not responsible for the decision taken by the authorities and travel agencies following your test.",
    "app.label.term2": "Please check your email and sms for confirmation of your request and your test appointment.",
    "app.label.term3": "You will have to pay only after the confirmation message from Zanmi lasante.",
    "app.label.term4": "To have access to the COVID-19 test you must pay a sum of 4000 gourdes",
    "app.label.term5": "ZL bank account numbers are: <br></br> 1) <b>HTG: 425-1921-1655-515</b><br></br>  2) <b>USD: 425-1922-1655-523</b><br></br>  Moncash number is:<b> (509) 4891-6120</b><br></br> You can submit an invoice on the moncash app too.",
    "app.label.term6": "No third party and no other number is authorized to receive money under Zanmi lasante's account for COVID-19 testing.",
    "app.label.term7": "If you pay at a merchant, keep the message and write down the Txn ID number somewhere: for your payment confirmation on the day of the debit, but we prefer payment on the moncash mobile application.",
    "app.label.term8": "come with the check on the day of the appointment, if you pay by check.",
    "app.label.term9": "In case someone else is paying for you, please indicate their name and the telephone number through which the transaction will be made.",

    "app.label.required": "This field is required",


    "app.label.nationality": "Nationality",
    "app.label.departure_date": "Date of departure",
    "app.label.firstname": "Firstname",
    "app.label.lastname": "Lastname",
    "app.label.gender": "Gender",
    "app.label.birthdate": "Birthdate",
    "app.label.male": "Male",
    "app.label.female": "Female",
    "app.label.age": "Age (Year)",
    "app.label.month": "Month (for children <1 year)",
    "app.label.phone": "Phone",
    "app.label.dest_contry": "Country of destination",
    "app.label.address": "Address in Haiti",
    "app.label.hassymptom": "Do you have symptoms suggestive of COVID-19?",
    "app.label.yes": "Yes",
    "app.label.no": "No",
    "app.label.cin": "CIN/NIF",
    "app.label.connexionfailled": "You have no internet connection",
    "app.label.connexionsuccess": "connection is reestablished",
    "app.label.previous": "Previous",
    "app.label.next": "Next",
    "app.label.personalInformation": "Personal Information",
    "app.label.paymentmode": "Method of payment",
    "app.label.email": "Email",
    "app.label.site": "What is the site of your choice for the direct debit?",
    "app.label.other": "Other",
    "app.label.submit": "Submit",
    "app.label.moncash": "Transaction number",
    "app.label.result": "how would you like to receive your result?",
    "app.label.bymail": "By email",
    "app.label.onsite": "From the collection center",
    "app.label.upload": "Upload deposit form",
    "app.label.confirm": "Your request is successful. a confirmation email has been sent to you",
    "app.label.invalid": "Invalid format",
    "app.label.NIF": "NIF",
    "app.label.CIN": "CIN",

    "app.label.greadOld": "Date cannot be greater than today",
    "app.label.lowerOld": "Date cannot be less than",
    "app.label.departureErrordate": "You have to choose a date that starts from tomorrow",
    "app.label.monthError": "The number of months must be < 12",
    "app.label.p_birthdate": "JJ/MM/YYYY",
    "app.label.request": "Request",
    "app.label.faqs": "Faqs Help",
    "app.label.invalidDate": "Invalid date",

    "app.label.departureFailled": "The departure date must be greater than or equal to 48 hours. ",
    "app.label.departureFailled1": "For any questions or information, do not hesitate to call us  ",
    "app.label.departureFailled2": "+(509) 2226-1006",

    "app.label.modalTitle": "TRAVELER'S INFORMATION GUIDE FOR OBTAINING A COVID 19 TEST",
    "app.label.modalSubTitle": "To facilitate the process of obtaining tests and reduce queues, please follow the various steps described below:",


    "app.label.q1": "How to make an appointment?",
    "app.label.q2": "How to pay for a test?",
    "app.label.q3": "How to find the collection centers?",
    "app.label.q4": "How to find the results?",

    "app.label.t1": "Registration Form",
    "app.label.t2": "Payment",
    "app.label.t3": "Specimens",
    "app.label.t4": "Results",


    "app.label.info1": '<p class="text-muted">Complete the PCR or antigen * test request form on the Zanmi Lasante website at: www.covid.zanmilasante.org/request or at the following address:  <a href="/request">https://covid.zanmilasante.org/request</a> </p>',
    "app.label.info2": '<p class="text-muted">Once the form is completed, it is advisable to proceed immediately to payment to confirm your appointment. This will be canceled 12 hours before the scheduled time of the debit if it is not paid.</p> <p>The payment will preferably be made by my cash but also by bank transfer. (See below) :</p> <p> Mon Cash Number: <b>(509) 4891-6120</b></p>  <p>Banking information :  <br></br><b>HTG: 425-1921-1655-515</b><br></br><b>USD: 425-1922-1655-523</b><br></br></p> <p class="text-muted">The price of the PCR is $ 75us or 6000gdes and that of the antigen test of $ 50us or 4000gdes. The amounts in gourdes are subject to change inherent to fluctuations in the foreign exchange market.</p>',
    "app.label.info3": ' <p class="text-muted">The samples for the PCR tests will be done between 8am and 12pm and the antigenic tests between 8am and 3am.</p>  <p>The Specimens sites are: <ul class="verti-timeline list-unstyled">   <li class="event-list">   <h5>Hôpital de Mirebalais </h5>  <p class="text-muted">Route Chatulée, Mirebalais, Centre, HT5210, </p>  </li> <li class="event-list">   <h5>Espace Chatelain Tours </h5>  <p class="text-muted"># 42, Route de l’aéroport, Tabarre  </p>  </li> </ul></p>',
    "app.label.info4": ' <p class="text-muted">The results will be delivered daily to the sampling sites. The results of the antigenic tests will be delivered on the day of the sample while the PCRs will be given 48h to 72h after the sample.</p>'
    , "app.label.passport": "No. Passport",
    "app.label.test_type": "Test type",
    "app.label.contact": "Contact",
    "app.label.fullname": "Full name",
    "app.label.message": "Message",
    "app.label.appointmentHour": "Appointment hour",
    "app.label.appointmentDate": "Appointment date",
    "app.label.depot": "Bank transfer",
    "app.label.MONCASH": "MONCASH",
    "app.label.DEPOSIT": "BANK DEPOSIT",
    "app.label.CASH": "CASH",
    "app.label.free": "FREE",
    "app.label.payment_Type": "Method of payment",
    "app.label.field": "This field",
    "app.label.field_required": "is required",
    "app.label.wait": "please wait...",

    "app.label.ag_departureFailled": "For the AG test, the appointment date must be less than or equal to 12 hours from the departure date",
    "app.label.pcr_departureFailled": "For the PCR test, the appointment date must be less than or equal to 72 hours from the departure date",

};


