import React from "react";
import {FormattedMessage} from "react-intl";
import {Field, reduxForm} from "redux-form";
import moment from 'moment'
import actions from "../../../../thunks/common/coreThunks";
import {connect, useDispatch, useSelector} from 'react-redux'
import {
    validate,
    normalizeCin,
    normalizeNif,
    normalizePhone,
    ageDayDif,
    ageDif,
    maxLength,
    normalizeCinNif,
    minDateNormalize,
    valDob,
    valNif,
    valCin,
    departureDate,
    handleAgeChange,
    handleBirtDateChange,
    renderError,
    birthDate,
    appointmentDate,
    email,
    maxLengthMob
} from "./validator";
import {required} from '../../../../constants/validators'
import {renderField} from "../../../common/utils/FormComponent";
import {getIntlMsg} from "../../../../constants/utils";
import {Country} from '../../../../constants/options/data';
import {reset, initialize, change} from 'redux-form'
import {useEffect, useState} from "react";
import {GoogleLogin} from 'react-google-login';
import swal from '@sweetalert/with-react'

const onClearForm = (reset, resetEntreprise, resetFile) => {
    reset('covid20');
    resetFile();
    resetEntreprise();
}

const Form = props => {
    const {handleSubmit, reset, pristine, submitting, specimentcenters, itemPrices, intl, appointments, departure, destination} = props;

    const test = (destination === "United States" || destination === "Mexico" ? "TEST PCR" : "TEST Anti Genique")

    const dispatch = useDispatch()
    const [testTypes, setSelectedTestType] = useState([]);
    const [testType, setSelectedtestTypes] = useState([]);

    const handleSelectedTestType = e => {
        setSelectedTestType(e)
    }

    const departureBilling = loc => {

    }

    console.log("_____________________________++++++++++++++++_______________", testType)

    const handleOnLoadAppointment = date => {
        const criteria = {options: ['appointments'], filter: date}
        dispatch(actions.loadItems(criteria));
    }

    return (
        <React.Fragment>
            <form onSubmit={handleSubmit}>
                <div className="form-row m-2">

                    <div className="alert alert-info col-md-12" role="alert">
                        <b><FormattedMessage id="app.label.personalInformation" defaultMessage="Step"/></b>
                    </div>


                    <div className="form-group floating-label show-label col-md-3">
                        <Field className="form-control" id="last_name" name="lastName" component={renderField}
                               required={true}
                               validate={[required]} type="text" label="app.label.lastname"
                               placeholder="app.label.lastname"/>
                    </div>

                    <div className="form-group floating-label show-label col-md-5">
                        <Field className="form-control" id="first_name" name="firstName" component={renderField}
                               required={true}
                               validate={[required]} type="text" label="app.label.firstname"
                               placeholder="app.label.firstname"/>
                    </div>


                    <div className="form-group floating-label show-label  col-md-4">

                        <h5 className="font-size-14 mb-4">
                            <span className="pb-5">
                                <FormattedMessage id="app.label.gender"/><span class="text-warning"> * </span>
                            </span>
                            <br/>
                            <div><Field name="gender" component={renderError}/></div>
                            <div className="pt-3">
                                <span className="ml-5">
                                    <Field name="gender" component="input" type="radio" value="MALE" id="male"
                                           validate={[required]}/> <label htmlFor="male">
                                          <FormattedMessage id="app.label.male"/>
                                    </label>
                                </span>

                                <span className="ml-4">
                                    <Field name="gender" component="input" type="radio" value="FEMALE"
                                           id="female"/> <label htmlFor="female">
                                        <FormattedMessage id="app.label.female" validate={[required]}/>
                                    </label>
                                </span>

                                <span className="ml-4">
                                    <Field name="gender" component="input" type="radio" value="other" id="other"
                                           validate={[required]}/>  <label htmlFor="other">
                                        <FormattedMessage id="app.label.other"/>
                                    </label>
                                </span>
                            </div>


                        </h5>


                    </div>


                    <div className="form-group floating-label show-label col-md-6">
                        <Field className="form-control" id="dob" name="dob" component={renderField} type="text"
                               onChange={(e) => handleBirtDateChange(e, dispatch)}
                               label="app.label.birthdate" placeholder="app.label.p_birthdate"
                               normalize={minDateNormalize} validate={[valDob(10, intl), birthDate(intl)]}/>
                    </div>

                    <div className="form-group floating-label show-label col-md-3">
                        <Field className="form-control" id="age" name="age" component={renderField}
                               onChange={(e) => handleAgeChange(e, dispatch)}
                               type="number" label="app.label.age" placeholder="app.label.age"/>
                    </div>

                    <div className="form-group floating-label show-label col-md-3">
                        <Field className="form-control" id="mob" name="mob" component={renderField} type="number"
                               label="app.label.month" placeholder="app.label.month"
                               validate={[maxLengthMob(12, intl)]}/>
                    </div>


                    <div className="form-group floating-label show-label col-md-6">
                        <Field className="form-control" id="phone" name="phone" component={renderField}
                               required={true}
                               type="text" label="app.label.phone" placeholder="app.label.phone"
                               normalize={normalizePhone} validate={[required]}/>
                    </div>

                    <div className="form-group floating-label show-label col-md-6">
                        <Field
                            className="form-control"
                            id="inputEmail6"
                            name="email"
                            component={renderField}
                            validate={[email]}
                            type="text"
                            label="app.label.email"
                            placeholder="app.label.email"
                        />
                    </div>

                    <div className="form-group floating-label show-label col-md-6">
                        <Field
                            className="form-control"
                            id="inputEmail6"
                            name="identifier"
                            required={true}
                            component={renderField}
                            validate={[required]}
                            type="text"
                            label="app.label.passport"
                            placeholder="app.label.passport"
                        />
                    </div>

                    {/*<div className="form-group floating-label show-label col-md-6">*/}
                    {/*<div className="row">*/}
                    {/*<div className="col-md-4">*/}
                    {/*<div className="row pt-4">*/}
                    {/*<div className="col-md-6">*/}
                    {/*<span className=""><Field name="indentifierType" component="input" type="radio"*/}
                    {/*value="NIF"*/}
                    {/*id="nif"*/}
                    {/*onChange={(e) => handleSelected(e.target.value)}/> <label*/}
                    {/*htmlFor="nif">NIF</label></span>*/}
                    {/*</div>*/}
                    {/*<div className="col-md-6">*/}
                    {/*<span className=""><Field name="indentifierType" component="input" type="radio"*/}
                    {/*value="CIN"*/}
                    {/*id="cin"*/}
                    {/*onChange={(e) => handleSelected(e.target.value)}/> <label*/}
                    {/*htmlFor="cin">CIN</label></span>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    {/*<div className="col-md-8">*/}
                    {/*<Field*/}
                    {/*className="form-control"*/}
                    {/*id="inputEmail6"*/}
                    {/*name="identifier"*/}
                    {/*component={renderField}*/}
                    {/*type="text"*/}
                    {/*normalize={selected === "NIF" ? normalizeNif : selected === "CIN" ? normalizeCin : ''}*/}
                    {/*validate={[required, selected === "NIF" ? valNif(13, intl) : selected === "CIN" ? valCin(22, intl) : '']}*/}
                    {/*label={`app.label.${selected}`}*/}
                    {/*placeholder={`app.label.${selected}`}*/}
                    {/*/>*/}
                    {/*</div>*/}
                    {/*</div>*/}


                    {/*</div>*/}

                    <div className="form-group floating-label show-label  col-md-6">
                        <Field
                            className="form-control"
                            id="option-message"
                            name="nationality"
                            required={true}
                            component={renderField}
                            validate={[required]}
                            type="select"
                            label="app.label.nationality"
                            placeholder="app.label.nationality"
                            options={Country}
                        />
                    </div>

                    <div className="form-group floating-label show-label  col-md-6">
                        <Field
                            className="form-control"
                            id="option-message"
                            name="destination"
                            required={true}
                            component={renderField}
                            validate={[required]}
                            type="select"
                            label="app.label.dest_contry"
                            placeholder="app.label.dest_contry"
                            options={Country}

                        />
                    </div>

                    <div className="form-group floating-label show-label col-md-6">
                        <Field
                            className="form-control"
                            id="inputEmail6"
                            name="departure"
                            required="true"
                            normalize={minDateNormalize}
                            component={renderField}
                            validate={[required, departureDate(intl)]}
                            type="text"
                            label="app.label.departure_date"
                            placeholder="app.label.p_birthdate"
                        />
                    </div>

                    <div className="form-group floating-label show-label col-md-12">
                        <Field
                            className="form-control"
                            id="inputEmail6"
                            name="address"
                            required={true}
                            validate={[required]}
                            component={renderField}
                            type="text"
                            label="app.label.address"
                            placeholder="app.label.address"
                        />
                    </div>

                    <div className="form-group col-md-12">
                        <div className="row">

                            <div className="col-md-12">
                                <Field name="symptoms" component={renderError}/>
                            </div>


                            <div className="col-md-7">
                                <h5 className="font-size-14 mb-4">

                                    <span className="pb-5">
                                        <FormattedMessage id="app.label.hassymptom"/><span
                                        class="text-warning"> * </span>
                                    </span>

                                    <span className="ml-5">
                                        <Field name="symptoms" component="input" type="radio" value="true"
                                               validate={[required]} id="yesss"/>{' '}
                                        <label htmlFor="yesss">
                                            <FormattedMessage id="app.label.yes"/>
                                        </label>
                                    </span>

                                    <span className="ml-4">
                                        <Field name="symptoms" component="input" type="radio" value="false"
                                               validate={[required]} id="noo"/>{' '}
                                        <label htmlFor="noo">
                                            <FormattedMessage id="app.label.no"/>
                                        </label>
                                    </span>


                                </h5>

                            </div>

                            <div className="form-group floating-label show-label  col-md-12">

                                <div className="">
                                    <h5 className="font-size-14 mb-4"><i className="fa fa-info text-primary mr-1"/>
                                        <span className="pb-5"><FormattedMessage id="app.label.site"/></span><span
                                            class="text-warning"> * </span>
                                    </h5>
                                </div>
                                {specimentcenters && specimentcenters.content.length > 0 ? specimentcenters.content.map((value, index) =>
                                        <div className="">
                                            <Field name="prelSite" component="input" type="radio"
                                                   value={value.id}
                                                   validate={[required]}
                                                   onChange={() => handleSelectedTestType(value.analyses)}
                                                   id={`site${index}`}/>{' '} <label
                                            htmlFor={`site${index}`}>{value.description}</label>
                                        </div>) : null}

                                <Field name="prelSite" component={renderError}/>
                            </div>

                            <div className="form-group floating-label show-label  col-md-5">

                                {testTypes && testTypes.length > 0 ?
                                    <div className="">
                                        <h5 className="font-size-14 mb-4"><i className="fa fa-info text-primary mr-1"/>
                                            <span className="pb-5">
                                            <FormattedMessage id="app.label.test_type"/><span
                                                class="text-warning"> * </span>
                                        </span>
                                        </h5>
                                    </div> : null}
                                {testTypes && testTypes.length > 0 ? testTypes.map((value, index) =>
                                        <div className="">
                                            <Field name="testType" component="input" type="radio"
                                                   value={value.id}
                                                   validate={[required]}
                                                   onChange={() => setSelectedtestTypes(value.name)}
                                                   id={`test_type${index}`}/>{' '} <label
                                            htmlFor={`test_type${index}`}>{value.description}</label>

                                        </div>) : null}

                                <Field name="testType" component={renderError}/>
                            </div>


                        </div>


                    </div>


                    <div className="form-group floating-label show-label col-md-6">
                        <Field
                            className="form-control"
                            id="inputEmail6"
                            name="appointmentDate"
                            required="true"
                            normalize={minDateNormalize}
                            component={renderField}
                            validate={[required, appointmentDate(intl, departure, destination,testType)]}
                            onChange={(e) => handleOnLoadAppointment(e.target.value)}
                            type="text"
                            label="app.label.appointmentDate"
                            placeholder="app.label.p_birthdate"
                        />
                    </div>
                    <div className="form-group floating-label show-label  col-md-6">
                        <Field
                            className="form-control"
                            id="option-message"
                            name="appointmentHour"
                            required={true}
                            component={renderField}
                            validate={[required]}
                            type="select"
                            label="app.label.appointmentHour"
                            placeholder="app.label.appointmentHour"
                            options={appointments}
                        />
                    </div>


                </div>
                <button
                    className="btn btn-primary float-right"
                    type="submit" disabled={pristine}>
                    <FormattedMessage id="app.label.next" defaultMessage="Next"/>
                </button>
            </form>

        </React.Fragment>

    );
}

const mapStateToProps = (state, props) => {
    return (
        {
            initialValues: {
                onSite: 'onSite',
                nationality: 'Haiti',
                destination: 'United States',
                idType: 'NIF',
                paymentType: 'CASH',
                appointmentHour: '8:00',

            }

        })
}
const afterSubmit = (result, dispatch, props) => {
}

export default connect(
    mapStateToProps
)(reduxForm({
    form: 'covid20',
    validate,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true

})(Form));

