import React from "react"
import {injectIntl, FormattedMessage} from "react-intl"
import {getIntlMsg} from "../../../constants/utils";
import {toast} from "react-toastify";


export const renderField = injectIntl(
    ({disabled, intl, input, label, placeholder, options, itemSize, labelSize, inputSize, type, required, meta: {asyncValidating, touched, error, warning}}) => (
        <div className="form-group">
            <label> <FormattedMessage id={label} defaultMessage={`Label Name`}/>{required ?
                <span class="text-warning"> * </span> : ''}</label>
            {type === "select" ? (
                    <>
                    <select {...input} className="form-control" id={label}>
                        <option></option>
                        {options.map((opt, key) => (
                            <option value={opt.id} key={key}>
                                {getIntlMsg(intl, opt.name)}
                            </option>
                        ))}
                    </select>
                    </>
                ) : type === "textarea" ? (
                        <div>
                            <div>
                                <textarea {...input} placeholder={getIntlMsg(intl, placeholder)} rows="10" cols="20"
                                          className="form-control"/>

                            </div>
                        </div>
                    ) : type === "password" ? (
                            <input
                                {...input}
                                className="form-control"
                                id={label}
                                type={type}
                                placeholder={getIntlMsg(intl, placeholder)}
                            />
                        ) : (
                            <input
                                {...input}
                                className="form-control"
                                id={label}
                                disabled={disabled}
                                type={type}
                                placeholder={getIntlMsg(intl, placeholder)}
                            />

                        )}

            { touched && error && (
                <div className="">
                    <small className="has-error" style={{fontSize: "12px", color: 'red'}}>
                        <FormattedMessage id={`app.label.${error}`} defaultMessage={error}/>
                    </small>
                </div>
            )}
        </div>
    )
)

//
//
// export const renderField = injectIntl(
//     ({intl, input, label, placeholder, type, id, readonly, required = false, meta: {asyncValidating, touched, error}}) => (
//         <div className="form-group">
//             <label htmlFor={label} className={`${required ? "text-danger" : ''}`}>
//                 <FormattedMessage id={label} defaultMessage={`Label Name`}/> :
//             </label>
//             <input {...input}
//                    className="form-control"
//                    readOnly={readonly}
//                    id={id} placeholder={getIntlMsg(intl, placeholder)}
//                    type={type}/>
//             {touched && error &&
//             <div className="text-danger">
//                 <small className="text-red" style={{fontSize: "12px"}}>
//                     <FormattedMessage id={error} defaultMessage={`Field error`}/>
//                 </small>
//             </div>
//             }
//         </div>
//     )
// );


//NOTE  Custom row common field
export const renderRowField = injectIntl(
    ({intl, input, label, placeholder, labelSize, inputSize, type, id, readonly, required = false, meta: {asyncValidating, touched, error}}) => (
        <div className="form-group row">
            <label htmlFor={label} className={`${required ? "text-danger" : ''} ${labelSize} form-label`}
                   style={{paddingTop: '20px', paddingRight: 0}}>
                <FormattedMessage id={label} defaultMessage={`Label Name`}/> :
            </label>
            <div className={inputSize}>
                <input {...input}
                       className="form-control"
                       readOnly={readonly}
                       id={id} placeholder={getIntlMsg(intl, placeholder)}
                       type={type}/>
            </div>

            {touched && error &&
            <div className="">
                <small className="text-red" style={{fontSize: "12px"}}>
                    <FormattedMessage id={error} defaultMessage={`Field error`}/>
                </small>
            </div>
            }
        </div>
    )
);


//NOTE  Custom row common field
export const renderRowCheckboxField = injectIntl(
    ({input, label, type, id, required = false, meta: {asyncValidating, touched, error}}) => (
        <React.Fragment>
            <label htmlFor={id} className={`${required ? "text-danger" : ''} kt-checkbox`}>
                <FormattedMessage id={label} defaultMessage={`Label Name`}/>
                <input {...input} id={id} type={type}/>
                <span/>
            </label>

            {touched && error &&
            <div className="">
                <small className="text-red" style={{fontSize: "12px"}}>
                    <FormattedMessage id={error} defaultMessage={`Field error`}/>
                </small>
            </div>
            }
        </React.Fragment>
    )
);


//NOTE  Custom row common field
export const renderSymptomRadioGroup = injectIntl(
    ({input, label, id, required = false, meta: {asyncValidating, touched, error}}) => (

        <div className="form-group row" style={{marginBottom: 0}}>
            <label htmlFor={id} className={`${required ? "text-danger" : ''} col-8 col-form-label`}>
                <FormattedMessage id={label} defaultMessage={`Yes`}/> :
            </label>
            <div className="col-lg-3">
                <div className="kt-radio-inline">
                    <label className="kt-radio kt-radio--bold kt-radio--brand">
                        <input  {...input} type="radio" value="yes"/>
                        <FormattedMessage id={`app.label.yes`} defaultMessage={`Yes`}/>
                        <span/>
                    </label>
                    <label className="kt-radio kt-radio--bold kt-radio--brand">
                        <input  {...input} type="radio" value="no"/>
                        <FormattedMessage id={`app.label.no`} defaultMessage={`No`}/>
                        <span/>
                    </label>
                    <label className="kt-radio kt-radio--bold kt-radio--brand">
                        <input  {...input} type="radio" value="unknown" checked={true}/>
                        <FormattedMessage id={`app.label.unknown`} defaultMessage={`Yes`}/>
                        <span/>
                    </label>

                </div>
                {
                    touched && error &&
                    <div className="">
                        <small className="text-red" style={{fontSize: "12px"}}>
                            <FormattedMessage id={error} defaultMessage={`Field error`}/>
                        </small>
                    </div>
                }
            </div>
        </div>
    )
);
