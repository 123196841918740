export default {
    "app.label.en": "Anglè",
    "app.label.fr": "Fransè",
    "app.label.ht": "Kreyòl",
    "app.label.dashboard": "Tablo rezime",
    "app.label.home": "Akèy",
    "app.label.sp": "Espanyol",


    "app.label.support": "Sipò",
    "app.label.track_your_orders": "Siveye kòmand ou yo!",

    "app.label.form.title": "COVID-19 fòm demann tès pou vwayajè yo",
    "app.label.terme.title": "Tèm ak kondisyon",
    "app.label.term1": "Zanmi Lasante pa responsab pou desizyon otorite yo ak ajans vwayaj yo te pran apre tès ou an.",
    "app.label.term2": "Tanpri tcheke imèl ou ak sms pou konfimasyon nan demann ou an ak randevou tès ou an.",
    "app.label.term3": "Ou pral oblije peye sèlman apre mesaj konfimasyon Zanmi lasante.",
    "app.label.term4": "Pou gen aksè a tès COVID-19 la ou dwe peye yon sòm 4000 goud",
    "app.label.term5": "Nimewo kont ZL yo se : <br></br> 1) <b>HTG: 425-1921-1655-515</b><br></br>  2) <b>USD: 425-1922-1655-523</b><br></br>  nimewo Moncash se: <b> (509) 4891-6120</b><br></br> ou ka soumèt yon fakti sou app Moncash la tou.",
    "app.label.term6": "Okenn twazyèm pati ak okenn lòt nimewo pa otorize pou resevwa lajan sou kont Zanmi lasante pou tès COVID-19.",
    "app.label.term7": "Si ou peye nan yon komèsan, kenbe mesaj la epi ekri nimewo a ID Txn yon kote: pou konfimasyon peman ou nan jou a nan debi a, men nou prefere peman sou aplikasyon an Moncash mobil.",
    "app.label.term8": "Vini ak chèk la nan jou randevou a, si ou peye pa chèk la.",
    "app.label.term9": "vini ak chèk la nan jou randevou a, si ou peye pa chèk la",
    "app.label.required": "Pati sa pa dwe vid",


    "app.label.nationality": "Nasyonalite",
    "app.label.departure_date": "Dat Wap vwayaje",
    "app.label.firstname": "Siyati",
    "app.label.lastname": "Premye non",
    "app.label.gender": "Sèks",
    "app.label.birthdate": "Dat nesans",
    "app.label.male": "Gason",
    "app.label.female": "Fi",
    "app.label.age": "Laj (Ane)",
    "app.label.month": "Mwa (pou <1 ane)",
    "app.label.phone": "Telefòn",
    "app.label.dest_contry": "Peyi wap vwayaje",
    "app.label.address": "Adrès an Ayiti:",
    "app.label.hassymptom": "Èske ou gen sentòm sijestif nan COVID-19?",
    "app.label.yes": "Wi",
    "app.label.no": "Non",
    "app.label.cin": "CIN/NIF",
    "app.label.connexionfailled": "Ou pa genyen koneksyon entènèt",
    "app.label.connexionsuccess": "Ou gen entènèt",
    "app.label.previous": "Avan",
    "app.label.next": "Pwochen",
    "app.label.personalInformation": "Enfòmasyon pèsonèl",
    "app.label.paymentmode": "Metòd peman",
    "app.label.email": "Imel",
    "app.label.site": "Ki sit ou chwazi pou al fe test la?",
    "app.label.other": "Lot",
    "app.label.submit": "Soumèt",
    "app.label.depo": "depo",
    "app.label.moncash": "Nimewo tranzaksyon an",
    "app.label.result": "ki jan ou ta renmen resevwa rezilta ou a?",
    "app.label.bymail": "Pa imèl",
    "app.label.onsite": "Soti nan sant ou te fe tes la",
    "app.label.upload": "Voye fich depo ou a",
    "app.label.confirm": "Demann ou an reyisi. yon imèl konfimasyon te voye ba ou",
    "app.label.invalid": "Fòma ki pa valab",
    "app.label.NIF": "NIF",
    "app.label.CIN": "CIN",

    "app.label.greadOld": "Dat pa ka pi gran pase jodi a",
    "app.label.lowerOld": "Dat pa ka mwens pase 1900",
    "app.label.departureErrordate": "Ou dwe chwazi yon dat ki kòmanse apati demen",
    "app.label.monthError": "kantite mwa yo dwe < 12",
    "app.label.p_birthdate": "JJ/MM/YYYY",
    "app.label.request": "Demand",
    "app.label.faqs": "Fak e Ed",
    "app.label.invalidDate": "Dat sa pa bon",

    "app.label.departureFailled": "Dat depa a dwe pi gran pase oswa egal a 48 èdtan.",
    "app.label.departureFailled1": "Pou nenpòt kesyon oswa enfòmasyon, pa ezite rele nou   ",
    "app.label.departureFailled2": "+(509) 4891-6120 ",

    "app.label.modalTitle": "GID ENFATIONMASYON POU VWAYAJÈ NAN JWENN YON TÈS COVID 19",
    "app.label.modalSubTitle": "Pou fasilite pwosesis pou jwenn tès yo epi redwi ke moun kap kriye, tanpri swiv etap sa yo divès kalite ki dekri anba a:",

    "app.label.q1": "Kouman pran yon randevou?",
    "app.label.q2": "Kouman pou peye pou yon tès?",
    "app.label.q3": "Ki jan yo jwenn sant sa yo koleksyon?",
    "app.label.q4": "Ki jan yo jwenn rezilta yo?",

    "app.label.t1": "Fòm Enskripsyon",
    "app.label.t2": "Peman",
    "app.label.t3": "Prélèvman",
    "app.label.t4": "Rezilta",

    "app.label.info1": '<p class="text-muted">Ranpli fòm demand tès PCR oswa antijèn * sou sit entènèt Zanmi Lasante nan: www.covid.zanmilasante.org/request oswa nan adrès sa a: <a href="https://covid.zanmilasante.org/request">https://covid.zanmilasante.org/request</a> </p>',
    "app.label.info2": '<p class="text-muted">Yon fwa ke fòm lan fin ranpli, li rekòmande pou ou kontinye imedyatman nan peman pou konfime randevou ou. Sa a pral anile 12 èdtan anvan lè a pwograme nan debi a si li pa peye.</p> <p>Peman ap de preferans fèt pa lajan kach mwen, men tou pa transfè labank. (Gade anba a):</p> <p>Nimewo Mon Cash : <b>(509) 4891-6120</b></p>  <p>Enfòmasyon bankè yo :  <br></br><b>HTG: 425-1921-1655-515</b><br></br><b>USD: 425-1922-1655-523</b><br></br></p> <p class="text-muted">Pri a nan PCR a se $ 75us oswa 6000gdes ak sa yo ki an tès la antijèn se $ 50us oswa 4000gdes. Kantite lajan an goud yo ka chanje nannan fluctuations nan mache a echanj etranje yo.</p>',
    "app.label.info3": ' <p class="text-muted">Echantiyon yo pou PCR yo pral fèt ant 8am ak 12pm ak tès yo antijèn ant 8am ak 3am.</p>  <p>Sit echantiyon yo se: <ul class="verti-timeline list-unstyled">  <li class="event-list">   <h5>Hôpital de Mirebalais</h5>  <p class="text-muted"> #000, Rue XXX, centre-ville de Mirebalais, Mirebalais, Centre</p>  </li>   </ul></p>',
    "app.label.info4": ' <p class="text-muted">Rezilta yo pral delivre chak jou nan sit echantiyon yo. Tès yo antijèn yo pral lage nan jou a nan echantiyon an pandan y ap PCRs yo pral lage 48h 72h apre echantiyon an.</p>  <p><b>N.B Yon gid pou ranpli fòm lan ak enfòmasyon adisyonèl ki disponib sou sit entènèt Zanmi Lasante nan adrès sa a: https: // ………… .. Oswa rele nimewo sa a: (509) -0000-0000.</b></p>'
    ,
    "app.label.passport": "Nimewo Paspò",
    "app.label.test_type": "Kalite tès la ",
    "app.label.contact": "Kontak",
    "app.label.fullname": "Non konplè",
    "app.label.message": "Mesaj",
    "app.label.appointmentHour": "Lè randevou ",
    "app.label.appointmentDate": "Dat randevou",

};
