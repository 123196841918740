export const hasEntryAccess = (roles, currentEntry) => {

    let status = false;
    const fieldWorkerEntries = ['admin','intermediaries','subscribers','obligation-categories','obligations','reports','general','obligation','report','email'];
    const administratorEntries = [
        'settings',
        ...fieldWorkerEntries
    ];
    const superAdminEntries = [
        ...administratorEntries
    ];

    roles.forEach((role) => {
            switch (role) {


                case 'FIELD_WORKER': {
                    administratorEntries.forEach((entry) => {
                        if (entry === currentEntry)
                            status = true;
                    });
                    break;
                }

                case 'ADMINISTRATOR': {
                    administratorEntries.forEach((entry) => {
                        if (entry === currentEntry)
                            status = true;
                    });
                    break;
                }


                case 'SUPER_ADMINISTRATOR': {
                    superAdminEntries.forEach((entry) => {
                        if (entry === currentEntry)
                            status = true;
                    });
                    break;
                }
                default:
                   status = true;
            }
        }
    );

    return status;
};


export const loadDefaultHomePage = roles => {
    switch (roles[0]) {
        case 'ADMINISTRATOR': {
            return `/entry`
        }
        case 'SUPER_ADMINISTRATOR':
            return `/`;
        default:
            return null
    }
};

