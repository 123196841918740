import types from "../actions/types/pageTypes";
import initialState from "../../constants/utils/initialState";

const inst = {
    content: [
        {
            id: "e69b85cd-3133-448e-b12f-f124bffc98c6",
            description: "Bureau Central Zanmi Lasante",
            testType: [{
                id: "e69b85cd-3133-448e-b12f-f124bffc98c6",
                description: "Test Anti Genique",
                name: "AG"
            },
                {id: "e69b85cd-3133-448e-b12f-f124bffc98c6", description: "TEST PCR", name: "PCR"}
            ]
        },
        {
            id: "b9b3c646-94f6-4dbd-907d-556764df00f3",
            description: "Hopital Universitaire de Mirbalais",
            testType: [{
                id: "b9b3c646-94f6-4dbd-907d-556764df00f3",
                description: "Test Anti Genique",
                name: "AG"
            },
            ]
        }
    ]
}

const appointments = [
    {id: '08:00', name: '8:00 AM'},
    {id: '09:00', name: '9:00 AM'},
    {id: '10:00', name: '10:00 AM'},
    {id: '11:00', name: '11:00 AM'},
    {id: '12:00', name: '12:00 PM'},
    {id: '13:00', name: '1:00 PM'},
    {id: '14:00', name: '2:00 PM'},
    {id: '15:00', name: '3:00 PM'},
    {id: '16:00', name: '4:00 PM'}
]

const sectorReducer = (state = initialState.pagesReducer, action) => {

        switch (action.type) {

            /**
             * LOAD LOAD_SECTORS_PAGE
             * */
            case types.LOAD_SECTORS_PAGE:
                return {...state, sector: {loadingItems: action.payload}};
            case types.LOAD_SECTORS_PAGE_SUCCEEDED:
                return {...state, sector: {item: action.payload, loadingItem: false}};
            case types.LOAD_SECTORS_PAGE_FAILED:
                return {...state, sector: {loadingItems: false}};

            /**
             * LOAD LOAD_SECTOR_PAGE
             * */
            case types.LOAD_SECTOR_PAGE:
                return {...state, loadingItem: action.payload};
            case types.LOAD_SECTOR_PAGE_SUCCEEDED:
                return {...state, item: action.payload, loadingItem: false};
            case types.LOAD_SECTOR_PAGE_FAILED:
                return {...state, loadingItem: false};

            /**
             * LOAD LOAD_SECTOR_PAGE
             * */
            case types.LOAD_SPECIMENCENTERS_PAGE:
                return {...state, specimencenters: {loadingItems: action.payload}};
            case types.LOAD_SPECIMENCENTERS_PAGE_SUCCEEDED:
                return {...state, specimencenters: {items: action.payload, loadingItem: false}};
            case types.LOAD_SPECIMENCENTERS_PAGE_FAILED:
                return {...state, specimencenters: {loadingItems: false}};


            /**
             * LOAD LOAD_NETWORKS_PAGE
             * */
            case types.LOAD_NETWORKS_PAGE:
                return {...state, network: {loadingItems: action.payload}};
            case types.LOAD_NETWORKS_PAGE_SUCCEEDED:
                return {...state, network: {item: action.payload, loadingItem: false}};
            case types.LOAD_NETWORKS_PAGE_FAILED:
                return {...state, network: {loadingItems: false}};


            /**
             * LOAD LOAD_NETWORK_PAGE
             * */
            case types.LOAD_NETWORK_PAGE:
                return {...state, network: {loadingItem: action.payload}};
            case types.LOAD_NETWORK_PAGE_SUCCEEDED:
                return {...state, network: {item: action.payload, loadingItem: false}};
            case types.LOAD_NETWORK_PAGE_FAILED:
                return {...state, network: {loadingItem: false}};

            /**
             * SAVE_CONTACT
             * */

            case types.SAVE_CONTACT:
                return {...state, contact: {loadingItem: action.payload}};
            case types.SAVE_CONTACT_SUCCEEDED:
                return {...state, contact: {item: action.payload, loadingItem: false}};
            case types.SAVE_CONTACT_FAILED:
                return {...state, contact: {loadingItem: false}};




            /**
             * SAVE_CONTACT
             * */

            case types.SAVE_REQUESTS:
                return {...state, requests: {loadingItem: action.payload, saved: true}};
            case types.SAVE_REQUESTS_SUCCEEDED:
                return {...state, requests: {item: action.payload, loadingItem: false, saved: false}};
            case types.SAVE_REQUESTS_FAILED:
                return {...state, requests: {loadingItem: false, saved: false}};



            /**
             * LOAD_BLOG_PAGE
             * */

            case types.LOAD_BLOG_PAGE:
                return {...state, blog: {loadingItem: action.payload}};
            case types.LOAD_BLOG_PAGE_SUCCEEDED:
                return {...state, blog: {item: action.payload, loadingItem: false}};
            case types.LOAD_BLOG_PAGE_FAILED:
                return {...state, blog: {loadingItem: false}};


            /**
             * LOAD_APPOINTMENTS_PAGE
             * */

            case types.LOAD_APPOINTMENTS_PAGE:
                return {...state, appointments: {loadingItems: action.payload}};
            case types.LOAD_APPOINTMENTS_PAGE_SUCCEEDED:
                return {...state, appointments: {items: action.payload, loadingItems: false}};
            case types.LOAD_APPOINTMENTS_PAGE_FAILED:
                return {...state, appointments: {items: appointments, loadingItems: false}};


            default:
                return state;
        }
    }
    ;

export default sectorReducer;
