import React from "react";
import * as validator from '../../../../constants/utils/validator'
import {formatDate} from '../../../../constants/utils/'
import {getIntlMsg} from "../../../../constants/utils";
import {injectIntl, FormattedMessage} from "react-intl"
import moment from 'moment';
import {format} from 'date-fns'
import swal from '@sweetalert/with-react'
import {reset, initialize, change} from 'redux-form'


export const validate = values => {
    const errors = {}

    //NOTE when calling validateString the params are: (label, value, min, max, required=false)
    errors.phone = validator.validateString('phone', values.phone, 3, 50, true)
    errors.dob = validator.validateString('dob', values.dob, 10, 10, true)
    errors.firstname = validator.validateString('firstname', values.firstname, 3, 30, true)
    errors.lastname = validator.validateString('lastname', values.lastname, 3, 30, true)
    errors.subject = validator.validateString('subject', values.subject, 3, 30, true)
    errors.message = validator.validateString('message', values.message, 3, 30, true)
    // errors.age = validator.validateString('age', values.age, 1, 3, true)


    const minDate = new Date(Date.now() - 24 * 60 * 60 * 1000)  // can't request less than 3 days before trip
    const maxDate = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)  // can't request more than 7 days before trip

    const minAppointmentDate = new Date(Date.now() - 24 * 60 * 60 * 1000)  // can't request more than 365 days before trip
    const maxAppointmentDate = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000)  // can't request more than 365 days before trip

    // errors.departure = validator.validateDate('departure', values.departure, minDate, maxDate, true);

    // errors.appointmentDate = validator.validateDate('appointmentDate', formatDate(values.appointmentDate), minAppointmentDate, maxAppointmentDate, true);
    // errors.appointmentDate = validator.validateDate('appointmentDate', formatDate(values.appointmentDate), minDate, new Date(formatDate(values.departure) || null), true);

    return errors
}

export const minDateNormalize = (val, prevVal) => {
    // Prevent non-digit characters being entered
    if (isNaN(parseInt(val[val.length - 1], 10))) {
        return val.slice(0, -1);
    }

    // When user is deleting, this prevents immediate re-addition of '/' when it's deleted
    if (prevVal && (prevVal.length >= val.length)) {
        return val;
    }

    // Add / at appropriate sections of the input
    if (val.length === 2) {
        val += '/';
    }
    if (val.length > 3 && val.length === 5) {
        val += '/';
    }
    // if (val.length >8 ) {
    //     val += '';
    // }

    // Prevent characters being entered after Dob is full
    if (val.length >= 10) {
        return val.slice(0, 10);
    }

    return val
};


const reformatDateString = (s) => {
    var b = s.split(/\D/);
    return b.reverse().join('-');
}

function convertDate(inputFormat) {
    function pad(s) {
        return (s < 10) ? '0' + s : s;
    }

    var d = new Date(inputFormat)
    return [pad(d.getMonth() + 1), pad(d.getDate()), d.getFullYear()].join('/')
}


export const birthDate = (intl) => value => {

    if (value) {
        var day = value.split("/")[0];
        var month = value.split("/")[1];
        var year = value.split("/")[2];

        const d = convertDate(`${month}/${day}/${year}`);
        const gha = moment(d).format('YYYY/MM/DD')

        if (gha !== "Invalid date") {
            if (value && (new Date(gha) < new Date('1900'))) {
                return getIntlMsg(intl, 'app.label.lowerOld')
            } else if (value && (new Date(gha) > new Date())) {
                return getIntlMsg(intl, 'app.label.greadOld')
            }
        } else {
            return getIntlMsg(intl, 'app.label.invalidDate')
        }


    }
};

export const renderError = ({meta: {touched, error}}) =>
    touched && error ?
        <small className="has-error" style={{fontSize: "12px", color: 'red'}}>
            <FormattedMessage id={`app.label.${error}`} defaultMessage={error}/>
        </small>
        : false


function _calculateAge(birthday) {
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

function _calculateMonth(birthday) {
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCMonth() - 12);
}


export const ageDayDif = (birthdate) => {
    var day = '';
    if (birthdate) {
        var day = birthdate.split("/")[0];
        var month = birthdate.split("/")[1];
        var year = birthdate.split("/")[2];
        const d2 = new Date(`${month}/${day}/${year}`)
        return _calculateAge(d2)
    }
}

export const MonthDif = (birthdate) => {
    var day = '';
    if (birthdate) {
        var day = birthdate.split("/")[0];
        var month = birthdate.split("/")[1];
        var year = birthdate.split("/")[2];
        const d2 = new Date(`${month}/${day}/${year}`)
        return _calculateMonth(d2)
    }
}

export const ageDif = (age) => {
    var year = '';
    if (age) {
        year = (new Date()).getFullYear() - age;
        return year;
    }
}

export const monthDif = (m) => {
    var month = '';
    if (m) {
        month = (new Date()).getUTCMonth() - m;
        return month;
    }
}

export const handleBirtDateChange = (e, dispatch) => {


    let val = ageDayDif(e.target.value);
    let val1 = MonthDif(e.target.value);
    if (val > 0)
        dispatch(change('covid20', 'age', val));
    dispatch(change('covid20', 'mob', 12 - val1));
}

export const handleAgeChange = (e, dispatch) => {

    let val = ageDif(e.target.value);
    if (val > 0)
        dispatch(change('covid20', 'dob', `15/05/${val}`));
    else dispatch(change('covid20', 'dob', null));
}


export const handleMonthChange = (e, dispatch) => {

    let val = ageDif(e.target.value);
    if (val > 0)
        dispatch(change('covid20', 'dob', `15/05/${val}`));
    else dispatch(change('covid20', 'dob', null));
}


export const departureInfo = (intl,ms) => {

    swal({
        icon: "info",
        text: ``,
        button: "Ok",
        content: (
            <div>
                <h5>{getIntlMsg(intl, ms)}</h5>
                <h5><b>{getIntlMsg(intl, 'app.label.departureFailled1')}</b></h5>
                <a href="tel:+5092226-1006" style={{fontSize: '20px'}}><i
                    className="fa fa-phone">{getIntlMsg(intl, 'app.label.departureFailled2')}</i></a>
            </div>
        )
    }).then((value) => {

    });
}


export const departureDate = (intl) => value => {

    if (value) {
        var day = value.split("/")[0];
        var month = value.split("/")[1];
        var year = value.split("/")[2];

        const d = convertDate(`${month}-${day}-${year}`);
        const d2 = new Date(`${month}/${day}/${year}`)
        const d1 = new Date()
        const gha = moment(d2).format('YYYY-MM-DD')

        var diff = d2.getTime() - d1.getTime();

        var daydiff = diff / (1000 * 60 * 60 * 24);

        var res = Math.round(daydiff);

        if (gha !== "Invalid date") {
            if (value && (new Date(``) < new Date())) {
                return getIntlMsg(intl, 'app.label.departureErrordate')
            }
            else {
                if (res >= 0) {
                    return undefined
                } else {
                    if (year.length === 4) {
                        // departureInfo(intl)
                        return getIntlMsg(intl, 'app.label.invalidDate')
                    }
                    else {
                        return undefined
                    }
                    // return getIntlMsg(intl, 'app.label.departureFailled')
                }
            }
        } else {
            return getIntlMsg(intl, 'app.label.invalidDate')
        }
    }
};

export const appointmentDate = (intl, departure, destination, testType) => value => {

    if (value && departure) {
        var day = value.split("/")[0];
        var month = value.split("/")[1];
        var year = value.split("/")[2];

        var day1 = departure.split("/")[0];
        var month1 = departure.split("/")[1];
        var year1 = departure.split("/")[2];


        const d2 = new Date(`${month}/${day}/${year}`)
        const d3 = new Date(`${month1}/${day1}/${year1}`)
        const d1 = new Date()
        const gha = moment(d2).format('YYYY-MM-DD');

        // const datd = new Date(formatDate(departure));

        var diff = d2.getTime() - d1.getTime();
        var difff = d3.getTime() - d1.getTime();


        var daydiff = diff / (1000 * 60 * 60 * 24);
        var daydifff = difff / (1000 * 60 * 60 * 24);
        // var dept = difff / (1000 * 60 * 60 * 12);

        var res = Math.round(daydiff);
        var res1 = Math.round(daydifff);
        // var resdept = Math.round(dept);

        var dept = res + res1;

        const dif = res1 - res;
        const result = res === -0 ? 0 : res

        console.log("_----------------------------", dif, "____________________--", res1, "+++++++++++++++=============", dept)

        if (gha !== "Invalid date") {
            if (value && (new Date(``) < new Date()) || result < 0) {
                return getIntlMsg(intl, 'app.label.invalidDate')
            }
            else {
                if (testType === "AG") {
                    if (result >= 0 && dif == 0 || dif <= 3) {
                        return undefined
                    } else {
                        if (year.length === 4) {
                            departureInfo(intl,"app.label.ag_departureFailled")
                            return getIntlMsg(intl, 'app.label.ag_departureFailled')
                        }
                        else {
                            return undefined
                        }
                    }
                } else {
                    if (result >= 0 && dif <= res1 && dif >= 2 && dif <= 3) {
                        return undefined
                    } else {
                        if (year.length === 4) {
                            departureInfo(intl,"app.label.pcr_departureFailled")
                            return getIntlMsg(intl, 'app.label.pcr_departureFailled')
                        }
                        else {
                            return undefined
                        }
                        // return getIntlMsg(intl, 'app.label.departureFailled')
                    }
                }
            }
        } else {
            return getIntlMsg(intl, 'app.label.invalidDate')
        }
    }
};

export const required = value => value ? undefined : 'Required';


export const maxLength = max => value =>
    value && value.length > max ? `Must be ${max} characters or less` : undefined


export const valNif = (max, intl) => value =>
    value && value.length < max ? getIntlMsg(intl, 'app.label.invalid') : undefined

export const valDob = (max, intl) => value =>
    value && value.length < max ? getIntlMsg(intl, 'app.label.invalid') : undefined


export const valCin = (max, intl) => value =>
    value && value.length < max ? getIntlMsg(intl, 'app.label.invalid') : undefined

export const maxLengthMob = (max, intl) => value =>
    value && value >= max ? getIntlMsg(intl, 'app.label.monthError') : undefined

export const maxLength15 = maxLength(15)

export const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined
export const minValue = min => value =>
    value && value < min ? `Must be at least ${min}` : undefined
export const minValue18 = minValue(18)
export const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
        'Invalid email address' : undefined
export const tooOld = value =>
    value && value > 65 ? 'You might be too old for this' : undefined
export const aol = value =>
    value && /.+@aol\.com/.test(value) ?
        'Really? You still use AOL for your email?' : undefined

export const normalizeNif = (value, previousValue) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (!previousValue || value.length > previousValue.length) {
        // typing forward
        if (onlyNums.length === 3) {
            return onlyNums + '-'
        }
        if (onlyNums.length === 6) {
            return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3) + '-'
        }
        if (onlyNums.length === 9) {
            return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 6) + '-' + onlyNums.slice(6) + '-'
        }
    }

    if (onlyNums.length <= 3) {
        return onlyNums
    }
    if (onlyNums.length <= 6) {
        return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3)
    }
    if (onlyNums.length <= 9) {
        return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 6) + '-' + onlyNums.slice(6)
    }

    return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 6) + '-' + onlyNums.slice(6, 9) + '-' + onlyNums.slice(9, 10)
}


export const normalizePhone = (value, previousValue) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (!previousValue || value.length > previousValue.length) {
        // typing forward
        if (onlyNums.length === 4) {
            return onlyNums + '-'
        }
        if (onlyNums.length === 8) {
            return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4, 8)
        }

    }

    if (onlyNums.length <= 4) {
        return onlyNums
    }
    if (onlyNums.length <= 8) {
        return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4, 8)
    }

    return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4, 8);
}


export const normalizeCin = (value, previousValue) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')

    if (onlyNums.length <= 10) {
        return onlyNums.slice(0, 10)
    } else {


        if (!previousValue || value.length > previousValue.length) {
            // typing forward
            if (onlyNums.length === 2) {
                return onlyNums + '-'
            }
            if (onlyNums.length === 4) {
                return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2) + '-'
            }
            if (onlyNums.length === 6) {
                return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2, 4) + '-' + onlyNums.slice(4) + '-'
            }
            if (onlyNums.length === 10) {
                return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2, 4) + '-' + onlyNums.slice(4, 6) + '-' + onlyNums.slice(6, 10) + '-'
            }
            if (onlyNums.length === 12) {
                return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2, 4) + '-' + onlyNums.slice(4, 6) + '-' + onlyNums.slice(6, 10) + '-' + onlyNums.slice(10, 12) + '-'
            }
        }

        if (onlyNums.length <= 2) {
            return onlyNums
        }
        if (onlyNums.length <= 4) {
            return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2)
        }
        if (onlyNums.length <= 6) {
            return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2, 4) + '-' + onlyNums.slice(4)
        }
        if (onlyNums.length <= 10) {
            return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2, 4) + '-' + onlyNums.slice(4, 6) + '-' + onlyNums.slice(6, 10)
        }
        if (onlyNums.length <= 12) {
            return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2, 4) + '-' + onlyNums.slice(4, 6) + '-' + onlyNums.slice(6, 10) + '-' + onlyNums.slice(10, 12)
        }

        return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2, 4) + '-' + onlyNums.slice(4, 6) + '-' + onlyNums.slice(6, 10) + '-' + onlyNums.slice(10, 12) + '-' + onlyNums.slice(12, 17)
    }
}
