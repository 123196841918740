import React from "react";
import {FormattedMessage} from "react-intl";
import {Field, reduxForm} from "redux-form";
import {connect, useDispatch} from 'react-redux'
import {validate, normalizeCin, normalizePhone, renderError} from "./validator";
import {renderField} from "../../../common/utils/FormComponent";
import {FileInput} from "../../../common/utils/FileInput";
import {getIntlMsg} from "../../../../constants/utils";
import {required} from '../../../../constants/validators'
import {useEffect, useState} from "react";
import {reset, initialize, change, formValueSelector} from 'redux-form';
import {useSelector} from "react-redux";
import Loader from "react-loader-spinner";

const WizardFormSecondPage = props => {
    const {handleSubmit, pristine, previousPage, submitting, loading} = props

    const [selected, setSelected] = useState('MONCASH');
    const [publishMode, setpublishMode] = useState('ON_SITE');

    const dispatch = useDispatch();

    const handleChangeMethod = m => {
        dispatch(change('covid20', 'paymentType', m));
        setSelected(m)

    }

    const paymentTypes = ['MONCASH', 'DEPOSIT', 'CASH']

    const handleChangePublishMode = e => {

        console.log("___________________________", e.target)


    }

    const {values} = useSelector(state => ({
        values: state.form ?.covid20 ?.values
}))
    ;


    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="alert alert-info col-md-12" role="alert">
                    <b><FormattedMessage id="app.label.paymentmode" defaultMessage="Step"/></b>
                </div>

                <div className="col-sm-12">

                    <div className="form-group">
                        <label className="font-weight-bold">
                            <FormattedMessage id={`app.label.payment_type`}/>
                            <span className="text-primary"> * </span> :
                        </label>
                        <div className="radio-inline">
                            {paymentTypes.map((element, i) =>
                                <label className="radio radio-lg ml-4" key={i}>
                                    <Field type="radio" component="input" name="paymentType" value={element}/>
                                    <FormattedMessage id={`app.label.${element}`}/>
                                </label>
                            )}
                        </div>
                        {/*{touched && error &&*/}
                        {/*<span className="form-text text-primary">*/}
                        {/*<FormattedMessage id={error}/>*/}
                        {/*</span>}*/}
                    </div>
                </div>

                {/*<div className="col-md-6 pb-4">*/}
                {/*<button type="button" className="btn btn-danger btn-lg btn-block waves-effect waves-light"*/}
                {/*onClick={() => handleChangeMethod('MONCASH')}>Mon Cash*/}
                {/*</button>*/}
                {/*</div>*/}
                {/*<div className="col-md-6">*/}
                {/*<button type="button" className="btn btn-primary btn-lg btn-block waves-effect waves-light"*/}
                {/*onClick={() => handleChangeMethod('DEPOT')}><FormattedMessage id="app.label.depot"/>*/}
                {/*</button>*/}
                {/*</div>*/}


                {values ?.paymentType === "MONCASH" ? <div className="form-group floating-label show-label col-md-6 pt-1">
                    <Field
                    className="form-control"
                    required={selected === 'MONCASH' ? true : false}
                    id="inputEmail6"
                    name="trxCode"
                    component={renderField}
                    validate={selected === 'MONCASH' ? [required] : ''}
                    type="text"
                    label="app.label.moncash"
                    placeholder="app.label.moncash"
                    />
                    </div> : values?.paymentType === "DEPOSIT" ?
                    <div className="form-group floating-label show-label  col-md-12 pt-1">

                    <div className="">
                    <span className="mr-3"><Field name="uploadFile" component={renderError}/></span>
                    <Field name="uploadFile" component={FileInput} type="file" id="uploadFile"
                    validate={[required]}/>{' '}
                    <label htmlFor="byMail"/>


                    </div>
                    </div>
                    : null}


                {/*{selected === "DEPOT" || selected === "MONCASH" ?*/}

                {/*<div className="form-group floating-label show-label  col-md-12 pt-5">*/}

                {/*<div className="">*/}
                {/*<h5 className="font-size-14 mb-4">*/}
                {/*<i className="fa fa-info text-primary mr-1"/>*/}
                {/*<span className="pb-5"><FormattedMessage id="app.label.result"/></span>*/}
                {/*</h5>*/}
                {/*</div>*/}
                {/*<div className="">*/}
                {/*<Field name="byMail" component="input" type="checkbox"*/}
                {/*id="byMail" onChange={() => handleChangePublishMode("BY_MAIL")}/>{' '} <label*/}
                {/*htmlFor="byMail"><FormattedMessage id="app.label.bymail"/></label>*/}
                {/*</div>*/}

                {/*<div className=""><Field name="onSite" component="input" type="checkbox"*/}
                {/*id="onSite"*/}
                {/*onChange={() => handleChangePublishMode("ON_SITE")}/>{' '} <label*/}
                {/*htmlFor="onSite"> <FormattedMessage id="app.label.onsite"/></label>*/}


                {/*</div>*/}

                {/*<span className="mr-3"><Field name="resultPublishingType" component={renderError}/></span>*/}

                {/*</div> : null}*/}

                <div className="form-group floating-label show-label col-md-6" style={{display: 'none'}}>
                    <Field
                        className="form-control"
                        id="inputEmail6"
                        name="paymentType"
                        component={renderField}
                        type="text"
                        label="app.label.address"
                        placeholder="app.label.address"
                    />
                </div>
            </div>


            <div className="col-md-12 pt-5">
                <button type="button" className="previous float-left btn btn-info " onClick={previousPage}>
                    <FormattedMessage id="app.label.previous" defaultMessage="Step"/>
                </button>
                <button type="submit" className="float-right btn btn-success">
                    {loading === true ? <> <span> <FormattedMessage id="app.label.wait" defaultMessage="Step"/></span>
                        <Loader
                            type="Puff"
                            color="#ff6900"
                            height={20}
                            width={20}
                            //3 secs
                        /></> : <FormattedMessage id="app.label.submit" defaultMessage="Step"/>}
                </button>
            </div>
        </form>
    )
}

const mapStateToProps = (state, props) => {
    return (
        {
            initialValues: {onSite: true, paymentType: "CASH"}

        })
}

export default connect(
    mapStateToProps
)(reduxForm({
    form: 'covid20', //Form name is same
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate
})(WizardFormSecondPage))
