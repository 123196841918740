import * as React from "react";
import {menu, settings, socialItems} from '../../../constants/options/footer'
import MenuBlock from "./MenuBlock";
import {Link} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import {getIntlMsg} from "../../../constants/utils";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import {Button} from 'react-bootstrap';

const Footer = injectIntl(({intl, handleChangeLanguage, language, history}) => {


    const [state, setState] = React.useState({
        lang: language,
        label: 'Francais', show: false
    });
    const langs = [
        {value: 'fr', label: 'Francais', logo: "/assets/img/fr.png"},
        {value: 'en', label: 'Anglais', logo: "/assets/img/en.png"}
    ]

    function setShowM() {
        setState({
            show: true, lang: language,
            label: 'Francais'
        })
    }

    function hide() {
        setState({
            show: false, lang: language,
            label: 'Francais'
        })
    }

    const {lang, label, show}=state;


    const handleChangeLang = e => {

        const val = e.target.value;
        let item = langs.find(v => v.value === val);
        setState({lang: item.value, label: item.label})
        handleChangeLanguage(val)
    }

    return (
        <footer class="footer">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">
                        <script>document.write(new Date().getFullYear())</script> © Zl-MedInfo.
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right d-none d-sm-block">
                            ZL-Covid-19 <i class="mdi mdi-heart text-danger"></i> by <a href="https://zanmilasante.org/" target="_blank" class="text-reset">Zl-MedInfo</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
})

export default React.memo(Footer)

