import types from "../../actions/types/setting/itemPriceActionTypes";
import initialState from "../../../constants/utils/initialState";

const itemPriceReducer = (state = initialState.itemPriceReducer, action) => {

        switch (action.type) {

            /**
             * LOAD IITEMPRICES_PAGE LIST
             * */
            case types.RESET_ITEMPRICE_PAGE:
                return {...state, item: {}};

            /**
             * LOAD IITEMPRICES_PAGE LIST
             * */
            case types.LOAD_ITEMPRICES_PAGE:
                return {...state, loadingItems: action.payload};
            case types.LOAD_ITEMPRICES_PAGE_SUCCEEDED:
                return {...state, items: action.payload?.content, loadingItems: false};
            case types.LOAD_ITEMPRICES_PAGE_FAILED:
                return {...state, loadingItems: action.payload};



            /**  LOAD ITEMPRICE_PAGE BY A SPECIFIC PROPERTY CASE */
            case types.LOAD_ITEMPRICE_PAGE:
                return {...state, loadingItem: true};
            case types.LOAD_ITEMPRICE_PAGE_SUCCEEDED:
                return {...state, item: action.payload, loadingItem: false};
            case types.LOAD_ITEMPRICE_PAGE_FAILED:
                return {...state, loadingItem: false};


            /**  SAVE ITEMPRICE_PAGE CASE */
            case types.SAVE_ITEMPRICE_PAGE:
                return {...state, savingOrUpdating: true};
            case types.SAVE_ITEMPRICE_PAGE_SUCCEEDED:
                return {
                    ...state, items: [...state.items, action.payload],
                    item: action.payload,
                    savingOrUpdating: false
                };
            case types.SAVE_ITEMPRICE_PAGE_FAILED:
                return {...state, savingOrUpdating: false};



            /**  UPDATE ITEMPRICE_PAGE CASE  */

            case types.UPDATE_ITEMPRICE_PAGE:
                return {...state, savingOrUpdating: true};
            case types.UPDATE_ITEMPRICE_PAGE_SUCCEEDED:
                return {
                    ...state,
                    items: state.items.map(item => item.id === action.payload.id ? action.payload : item),
                    item: action.payload
                };
            case types.UPDATE_ITEMPRICE_PAGE_FAILED:
                return {...state, savingOrUpdating: false};


            /**  DELETE ITEMPRICE_PAGE CASE  */
            case types.DELETE_ITEMPRICE_PAGE:
                return {...state, deleting: true};
            case types.DELETE_ITEMPRICE_PAGE_SUCCEEDED:
                return (action.payload.status === 200) ?
                    {...state, items: state.items.filter(item => item.id !== action.payload.item.id), deleting: false}
                    : state;
            case types.DELETE_ITEMPRICE_PAGE_FAILED:
                return {...state, deleting: false};

            default:
                return state;
        }
    }
;

export default itemPriceReducer;
