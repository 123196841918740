/**
 * Created by louidorpc on 1/26/21.
 */
import * as React from "react";
import useDocumentTitle from "../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../constants/utils";
import {injectIntl} from "react-intl";
import actions from "../../../thunks/common/coreThunks";
import {useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {RequestModel, ContactDTO} from '../../../constants/models'
import swal from 'sweetalert'
import {useParams, withRouter} from "react-router-dom";
import {useEffect} from "react";
import ReactPlayer from 'react-player/lazy';
import FormContact from './form'

const HomePage = injectIntl(({intl}) => {
    const {module} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.contact`));
    const dispatch = useDispatch();


    const handleSubmiteSucce = (values) => {

        swal({
            title: `${values.firstName ? values.lastName : null}, ${getIntlMsg(intl, 'app.label.confirm')}`,
            text: "",
            icon: "success",
            button: "Next",
        }).then((value) => {
            document.location = "/";
        });
    }


    const handleSubmite = values => {
        const ent = new ContactDTO(values)

        const entity = {
            options: ['CONTACT'], action: 'save', intl: intl, item: ent
        }
        dispatch(actions.saveOrUpdateItem(entity));
    }

    const {loading, item, specimentcenters} = useSelector(state => ({
        loading: state.pagesReducer.requests.loadingItem,
        item: state.pagesReducer.requests.item,
        specimentcenters: state.pagesReducer.specimencenters.items,
    }));


    return (
        <React.Fragment>
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">


                            <div class="row mt-5">
                                <div class="col-xl-3 col-sm-5 mx-auto">
                                    <div>
                                        <img src="assets/images/contact.png" alt="" class="img-fluid mx-auto d-block"/>
                                    </div>
                                </div>

                                <div class="col-xl-8">
                                    <div id="faqs-accordion" class="custom-accordion mt-5 mt-xl-0">
                                        <div class="card border shadow-none">
                                            <a href="#faqs-gen-ques-collapse" class="text-dark" data-toggle="collapse"
                                               aria-expanded="true" aria-controls="faqs-gen-ques-collapse">
                                                <div class="bg-light p-3">

                                                    <div class="media align-items-center">
                                                        <div class="mr-3">
                                                            <div class="avatar-xs">
                                                                <div class="avatar-title rounded-circle font-size-22">
                                                                    <i class="fa fa-envelope"></i>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="media-body overflow-hidden">
                                                            <h5 class="font-size-16 mb-1">{getIntlMsg(intl, 'app.label.contact')}</h5>
                                                            <p class="text-muted text-truncate mb-0"></p>
                                                        </div>

                                                    </div>

                                                </div>
                                            </a>

                                            <div id="" class="show">
                                                <div class="p-4">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div>
                                                                <div class="media mt-4">

                                                                    <div class="media-body">
                                                                        <FormContact onSubmit={handleSubmite}/>

                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
});

export default React.memo(HomePage)

