const itemPriceActionTypes = {

    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * RESET ITEMPRICE_PAGE ACTION TYPES
     * -------------------------------------------
     * */
    RESET_ITEMPRICE_PAGE: "RESET_ITEMPRICE_PAGE",

    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD ITEMPRICES_PAGE ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_ITEMPRICES_PAGE: "LOAD_ITEMPRICES_PAGE",
    LOAD_ITEMPRICES_PAGE_SUCCEEDED: "LOAD_ITEMPRICES_PAGE_SUCCEEDED",
    LOAD_ITEMPRICES_PAGE_FAILED: "LOAD_ITEMPRICES_PAGE_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * LOAD SINGLE ITEMPRICE_PAGE ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_ITEMPRICE_PAGE: "LOAD_ITEMPRICE_PAGE",
    LOAD_ITEMPRICE_PAGE_SUCCEEDED: "LOAD_ITEMPRICE_PAGE_SUCCEEDED",
    LOAD_ITEMPRICE_PAGE_FAILED: "LOAD_ITEMPRICE_PAGE_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * SAVE ITEMPRICE_PAGE ACTION TYPES
     * -------------------------------------------
     * */
    SAVE_ITEMPRICE_PAGE: "SAVE_ITEMPRICE_PAGE",
    SAVE_ITEMPRICE_PAGE_SUCCEEDED: "SAVE_ITEMPRICE_PAGE_SUCCEEDED",
    SAVE_ITEMPRICE_PAGE_FAILED: "SAVE_ITEMPRICE_PAGE_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * UPDATE ITEMPRICE_PAGE ACTION TYPES
     * -------------------------------------------
     * */
    UPDATE_ITEMPRICE_PAGE: "UPDATE_ITEMPRICE_PAGE",
    UPDATE_ITEMPRICE_PAGE_SUCCEEDED: "UPDATE_ITEMPRICE_PAGE_SUCCEEDED",
    UPDATE_ITEMPRICE_PAGE_FAILED: "UPDATE_ITEMPRICE_PAGE_FAILED",


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * DELETE ITEMPRICE_PAGE ACTION TYPES
     * -------------------------------------------
     * */
    DELETE_ITEMPRICE_PAGE: "DELETE_ITEMPRICE_PAGE",
    DELETE_ITEMPRICE_PAGE_SUCCEEDED: "DELETE_ITEMPRICE_PAGE_SUCCEEDED",
    DELETE_ITEMPRICE_PAGE_FAILED: "DELETE_ITEMPRICE_PAGE_FAILED",


};


export default itemPriceActionTypes;
