import React, {Component} from 'react'
import PropTypes from 'prop-types'
import WizardFormFirstPage from './step1'
import WizardFormSecondPage from './step2'
import swal from 'sweetalert'
import {getIntlMsg, intl} from "../../../../constants/utils";
import {withCookies, Cookies} from 'react-cookie';
import {instanceOf} from 'prop-types'


class WizardForm extends Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props)
        this.nextPage = this.nextPage.bind(this)
        this.previousPage = this.previousPage.bind(this)
        this.state = {
            page: 1
        }
    }

    nextPage() {
        this.setState({page: this.state.page + 1})
    }

    previousPage() {
        this.setState({page: this.state.page - 1})
    }

    const
    handleSubmitForm = values => {
        const {cookies} = this.props;


    }

    render() {
        const {onSubmit, specimentcenters, itemPrices, intl, appointments, handleOnLoadAppointment, departure, destination, testType, loading} = this.props
        const {page} = this.state
        return (
            <div>
                {page === 1 &&
                <WizardFormFirstPage onSubmit={this.nextPage} {...{
                    specimentcenters,
                    itemPrices,
                    intl,
                    appointments,
                    departure,
                    destination,
                    testType
                }}
                />}
                {page === 2 && (
                    <WizardFormSecondPage
                        previousPage={this.previousPage}
                        onSubmit={this.props.handleSubmite}
                        {...{loading}}
                    />
                )}

            </div>
        )
    }
}

WizardForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
}

export default WizardForm