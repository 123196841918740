const singleParams = {
    item: null,
    loadingItem: true,
}

const baseParams = {
    ...singleParams,
    items: [],
    loadingItems: true,
    saved: false,
    pageParam: null
}
const listParams = {
    items: [],
    loading: false,
}

const moduleParams = {
    ...baseParams,
    firstItem: null,
    deleting: false,
    savingOrUpdating: false,
};
const counterParams = {
    counter: 0,
    loading: false,
}


export default {

    coreReducer: {
        locale: "fr",
        currency: "gds",
        country: "ht"
    },
    proxyReducer: {},

    settingReducer: {...moduleParams,},

    aboutReducer: {
        leadership: {...singleParams},
        origin: {...singleParams},
        impact: {...singleParams},
        focus: {...singleParams},
        missionVision: {...singleParams},
    },

    pagesReducer: {
        sector: {...singleParams},
        contact: {...singleParams},
        network: {...singleParams},
        blog: {...singleParams},
        requests: {...singleParams},
        specimencenters: {...singleParams},
        appointments: {...singleParams},
    },


    itemPriceReducer: {...moduleParams, ...counterParams},


};
