const sectorActionTypes = {

    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * SECTORS PAGE ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_SECTORS_PAGE: "LOAD_SECTORS_PAGE",
    LOAD_SECTORS_PAGE_SUCCEEDED: "LOAD_SECTORS_PAGE_SUCCEEDED",
    LOAD_SECTORS_PAGE_FAILED: "LOAD_SECTORS_PAGE_FAILED",


    LOAD_BLOG_PAGE: "LOAD_BLOG_PAGE",
    LOAD_BLOG_PAGE_SUCCEEDED: "LOAD_BLOG_PAGE_SUCCEEDED",
    LOAD_BLOG_PAGE_FAILED: "LOAD_BLOG_PAGE_FAILED",


    LOAD_BLOGS_PAGE: "LOAD_BLOGS_PAGE",
    LOAD_BLOGS_PAGE_SUCCEEDED: "LOAD_BLOGS_PAGE_SUCCEEDED",
    LOAD_BLOGS_PAGE_FAILED: "LOAD_BLOGS_PAGE_FAILED",

    LOAD_SPECIMENCENTERS_PAGE:"LOAD_SPECIMENCENTERS_PAGE",
    LOAD_SPECIMENCENTERS_PAGE_SUCCEEDED:"LOAD_SPECIMENCENTERS_PAGE_SUCCEEDED",
    LOAD_SPECIMENCENTERS_PAGE_FAILED:'LOAD_SPECIMENCENTERS_PAGE',


    /**
     * @Author Rikenson JACQUES (The Tiger ^(..)^)
     * SECTOR PAGE ACTION TYPES
     * -------------------------------------------
     * */
    LOAD_SECTOR_PAGE: "LOAD_SECTOR_PAGE",
    LOAD_SECTOR_PAGE_SUCCEEDED: "LOAD_SECTOR_PAGE_SUCCEEDED",
    LOAD_SECTOR_PAGE_FAILED: "LOAD_SECTOR_PAGE_FAILED",


    LOAD_NETWORKS_PAGE: "LOAD_NETWORKS_PAGE",
    LOAD_NETWORKS_PAGE_SUCCEEDED: "LOAD_NETWORKS_PAGE_SUCCEEDED",
    LOAD_NETWORKS_PAGE_FAILED: "LOAD_NETWORKS_PAGE_FAILED",


    LOAD_NETWORK_PAGE: "LOAD_NETWORK_PAGE",
    LOAD_NETWORK_PAGE_SUCCEEDED: "LOAD_NETWORK_PAGE_SUCCEEDED",
    LOAD_NETWORK_PAGE_FAILED: "LOAD_NETWORK_PAGE_FAILED",

    SAVE_CONTACT: "SAVE_CONTACT",
    SAVE_CONTACT_SUCCEEDED: "SAVE_CONTACT_SUCCEEDED",
    SAVE_CONTACT_FAILED: "SAVE_CONTACT_FAILED",


    SAVE_REQUESTS: "SAVE_REQUESTS",
    SAVE_REQUESTS_SUCCEEDED: "SAVE_REQUESTS_SUCCEEDED",
    SAVE_REQUESTS_FAILED: "SAVE_REQUESTS_FAILED",

    LOAD_APPOINTMENTS_PAGE: "LOAD_APPOINTMENTS_PAGE",
    LOAD_APPOINTMENTS_PAGE_SUCCEEDED: "LOAD_APPOINTMENTS_PAGE_SUCCEEDED",
    LOAD_APPOINTMENTS_PAGE_FAILED: "LOAD_APPOINTMENTS_PAGE_FAILED",

};


export default sectorActionTypes;