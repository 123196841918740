import * as api from "../../constants/api";
import {toast} from "react-toastify";
import actions from "../../redux/actions/creators/common";
import {getIntlMsg} from "../../constants/utils";

const apiName = `default`;

/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * @Desc: Reset entityName to null
 * */
const resetItem = (params) => async(dispatch) => {
    dispatch(actions.clearItem(params));
};

/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * @Desc: Fetch an item  by id
 * @return function(*): Promise<AxiosResponse<T>>
 * */
const loadItem = params => async(dispatch) => {
    try {
        dispatch(actions.setLoadedItem(params));
        const response = await api.findOne({...params, apiName});
        dispatch(actions.setLoadedItemSucceeded({...params, payload: response.data}));
    } catch (e) {
        console.log(e);
        dispatch(actions.setLoadedItemFailed(params));
    }
};

/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * @Desc: Fetch all items
 * @return function(*): Promise<AxiosResponse<T>>
 * */
const loadItems = params => async(dispatch) => {
    try {
        dispatch(actions.setLoadedItems(params));
        const response = await api.findCollection({...params, apiName});
        dispatch(actions.setLoadedItemsSucceeded({...params, payload: response.data}));
    } catch (e) {
        console.log(e);
        dispatch(actions.setLoadedItemsFailed(params));
    }
};

/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * @Desc: Fetch all items list of value
 * @return function(*): Promise<AxiosResponse<T>>
 * */
const loadListOfValue = (params) => async(dispatch) => {
    try {
        dispatch(actions.beginLovApiCall());
        const response = await api.findCollection({...params, apiName: apiName});
        dispatch(
            actions.setLoadItemsListOfValue({...params, items: response.data})
        );
        dispatch(actions.apiLovCallSuccess());
    } catch (e) {
        console.log(e);
        dispatch(actions.apiLovCallError());
    }
};

/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * @Desc: Save or Update item
 * @return function(*): Promise<AxiosResponse<T>>
 * */

const saveOrUpdateItem = params => async(dispatch) => {
    const {options, action, intl} = params;

    try {
        dispatch(actions.setSavedOrUpdatedItem(params));
        const response = await api.saveOrUpdate({...params, apiName: apiName});
        dispatch(actions.setSavedOrUpdatedItemSucceeded({...params, payload: response.data}));
        // toast.success(getIntlMsg(intl, `app.label.${options[0]}_${action === "save" ? "saved" : "updated"}_succeeded`.replace("-", "_")));
    } catch (e) {
        console.log(e);
        dispatch(actions.setSavedOrUpdatedItemFailed(params));
        toast.error(getIntlMsg(intl, `app.label.${options[0]}_${action === "save" ? "saved" : "updated"}_failed`.replace("-", "_")));
    }
};

/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * @Desc: Delete an item
 * @return function(*): Promise<AxiosResponse<T>>
 * */
const removeItem = (params) => async(dispatch) => {
    const {options, intl} = params;
    try {
        dispatch(actions.setDeletedItem(params));
        const response = await api.deleteOne({...params, apiName: apiName});
        dispatch(actions.setDeletedItemSucceeded({...params, status: response.status}));
        toast.success(getIntlMsg(intl, `app.label.${options[0]}_deleted_succeeded`.replace("-", "_")));
    } catch (e) {
        console.log(e);
        dispatch(actions.setDeletedItemFailed(params));
        toast.error(getIntlMsg(intl, `app.label.${options[0]}_deleted_failed`.replace("-", "_")));
    }
};

/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * @Desc: Delete an item
 * @return function(*): Promise<AxiosResponse<T>>
 * */
const changeStatus = (params) => async(dispatch) => {
    const {entityName, item, intl} = params;
    try {
        dispatch(actions.beginChangeStatusApiCall());
        const response = await api.changeStatus({
            ...params,
            apiName: apiName,
            item: item,
        });
        dispatch(actions.setChangeItemStatus({...params, item: response.data}));
        dispatch(actions.apiChangeStatusCallSuccess());
        toast.success(getIntlMsg(intl, `app.label.${entityName}_status_change_succeeded`.replace("-", "_")));
    } catch (e) {
        console.log(e);
        dispatch(actions.apiChangeStatusCallError());
        toast.error(getIntlMsg(intl, `app.label.${entityName}_status_change_failed`.replace("-", "_")));
    }
};

/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * @Desc: Fetch dashboard data
 * @return function(*): Promise<AxiosResponse<T>>
 * */
const isPropertyExists = async(params) => {
    try {
        const response = await api.isPropertyExists({
            ...params,
            apiName: apiName,
        });
        return response.data.length > 0;
    } catch (e) {
        console.log(e);
    }
};

/**
 * @Author Rikenson JACQUES (The Tiger ^(..)^)
 * @Desc: Switch an item
 * @return function(*): Promise<AxiosResponse<T>>
 * */
const switchItem = params => async(dispatch) => {
    try {
        dispatch(actions.setSwitchItem(params));
    } catch (e) {
        console.log(e);
    }
};


export default {
    resetItem,
    loadItem,
    loadItems,
    loadListOfValue,
    saveOrUpdateItem,
    removeItem,
    changeStatus,
    switchItem,
    isPropertyExists,
};