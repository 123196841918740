import React, {useState} from "react";
import "react-toastify/dist/ReactToastify.css";
import {IntlProvider} from "react-intl";
import {ConnectedRouter} from "connected-react-router";
import {Switch} from "react-router-dom";
import messages from "../constants/messages";
import routes from '../constants/routes'
import PrivateRoute from "./common/routes/PrivateRoute";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from "./layout/footer";
import TopBar from "./layout/header/TopBar";
import {withCookies, Cookies} from 'react-cookie';
import {instanceOf} from 'prop-types'
import {getIntlMsg, intl} from "../constants/utils";


toast.configure()

export const GlobalContext = React.createContext({});

class Layout extends React.PureComponent {


    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };


    constructor(props) {
        super(props);
        const {cookies} = props;
        this.state = {
            language: cookies.get('name') || 'fr',
        };
    }

    state = {
        language: 'fr'
    }

    handleChangeLanguage = lg => {
        const {cookies} = this.props;
        cookies.set('name', lg, {path: '/'});
        this.setState({language: lg})

    }

    handleOnSendMessage = (message) => {
        this.setState({
            messages: this.state.messages.concat({
                author: {
                    username: 'user1',
                    id: 1,
                    avatarUrl: 'https://image.flaticon.com/icons/svg/2446/2446032.svg',
                },
                text: message,
                timestamp: +new Date(),
                type: 'text',
            }),
        });
    };

    componentDidMount() {

        // toast.info("", {
        //     position: toast.POSITION.TOP_RIGHT,
        //     render:<h1>hello</h1>
        // });
        const {language}=this.state;

        window.addEventListener('offline', function (e) {
            toast.error(getIntlMsg(intl(language), 'app.label.connexionfailled'), {
                position: toast.POSITION.TOP_RIGHT
            });
        });

        window.addEventListener('online', function (e) {
            toast.success(getIntlMsg(intl(language), 'app.label.connexionsuccess'), {
                position: toast.POSITION.TOP_RIGHT
            });
        });
    }

    render() {
        const {history, cookies}=this.props;


        const {language}=this.state;

        return (
            <IntlProvider locale={'fr'} messages={messages[language]}>
                <ConnectedRouter history={history}>

                    <TopBar handleChangeLanguage={this.handleChangeLanguage} language={language} history={history}/>
                    <div class="main-content">
                        <div class="page-content">
                            <div class="container-fluid">
                                <Switch>
                                    {/*<PrivateRoute exact path={`/`} component={GlobalHomePage}/>*/}
                                    {routes.map((route, index) =>
                                        <PrivateRoute key={index} path={route.path} exact={route.exact}
                                                      component={route.main}/>
                                    )}
                                    {/*<PrivateRoute component={PageNotFound}/>*/}
                                </Switch>
                            </div>
                        </div>

                        <Footer handleChangeLanguage={this.handleChangeLanguage} language={language} history={history}/>
                    </div>
                </ConnectedRouter>
            </IntlProvider>
        )
    }
}

export default React.memo(withCookies(Layout));