import * as React from "react";
import useDocumentTitle from "../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../constants/utils";
import {injectIntl} from "react-intl";
import {Terms} from '../../../constants/options/data';
import actions from "../../../thunks/common/coreThunks";
import {useState} from 'react'
//import Form from './form';
import WizardForm from './form/WizardForm';
import {useDispatch, useSelector} from "react-redux";
import {RequestModel, Applicant, RequestDTO} from '../../../constants/models'
import swal from 'sweetalert'
import {useParams, withRouter} from "react-router-dom";
import {useEffect} from "react";
import ModalInfo from './modalInfo';
import HomeFaqs from '../home'

const GlobalHomePage = injectIntl(({intl}) => {
    const {module} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.request`));
    const dispatch = useDispatch();

    const option = {options: ['itemprices']};
    const criteria = {options: ['specimencenters']}

    useEffect(() => {
        dispatch(actions.loadItems(criteria));
        dispatch(actions.loadItems(option));
    }, [dispatch]);


    const handleSubmiteSucce = (values) => {

        swal({
            title: `${values.firstName ? values.lastName : null}, ${getIntlMsg(intl, 'app.label.confirm')}`,
            text: "",
            icon: "success",
            button: "Next",
        }).then((value) => {
            document.location = "/request";
        });
    }


    const [formData, setFormData] = useState({});

    const handleSubmite = values => {
        const ent = new RequestDTO(values)

        console.log(ent, '**********************8')

        const entity = {
            options: ['REQUESTS'], action: 'save', intl: intl, item: ent
        }
        setFormData(values);
        dispatch(actions.saveOrUpdateItem(entity));
    }

    const {loading, item, specimentcenters, itemPrices, appointments,departure,destination,testType} = useSelector(state => ({
        loading: state.pagesReducer.requests.saved,
        item: state.pagesReducer.requests.item,
        specimentcenters: state.pagesReducer.specimencenters.items,
        appointments: state.pagesReducer.appointments.items ? state.pagesReducer.appointments.items : [],
        loadingPrices: state.itemPriceReducer.loadingItems,
        itemPrices: state.itemPriceReducer.items,
        departure: state.form.covid20?.values?.departure||'',
        destination: state.form.covid20?.values?.destination||'',
        testType: state.form.covid20?.values?.testType||'',
    }));

    if (item && item.id != null) handleSubmiteSucce(formData)

    return (
        <React.Fragment>
            <div className="row">

                <div className="col-md-4 col-xl-4">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mt-0">{getIntlMsg(intl, `app.label.terme.title`)}</h4>
                            <h6 className="card-subtitle font-14 text-muted">
                            </h6>
                        </div>
                        <div className="card-body">

                            <ol className="activity-feed mb-0 pl-2" data-simplebar="init"
                                style={{maxHeight: "calc(70vh - 80px)"}}>
                                <div className="simplebar-wrapper" style={{margin: "0px 0px 0px -8px"}}>
                                    <div className="simplebar-height-auto-observer-wrapper">
                                        <div className="simplebar-height-auto-observer"/>
                                    </div>
                                    <div className="simplebar-mask">
                                        <div className="simplebar-offset" style={{right: "-12px", bottom: "0px"}}>
                                            <div className="simplebar-content-wrapper"
                                                 style={{height: "auto", overflow: "hidden scroll"}}>
                                                <div className="simplebar-content" style={{padding: "0px 0px 0px 8px"}}>

                                                    {Terms && Terms.length > 0 ? Terms.map(value => <li
                                                            className="feed-item">
                                                            <div className="feed-item-list">
                                                                <p className="text-muted mb-1 font-size-13">
                                                                    <b>{value.id}</b>
                                                                    <small className="d-inline-block ml-1"/>
                                                                </p>
                                                                <p className="mt-0 mb-0">
                                                                    <div dangerouslySetInnerHTML={{
                                                                        __html: intl.formatMessage({id: value.title}, {value: '<strong>STRONG</strong>'})
                                                                    }} style={{}}/>
                                                                </p>
                                                            </div>
                                                        </li>) : null}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="simplebar-placeholder"
                                         style={{width: "auto", height: "474px"}}/>
                                </div>
                                <div className="simplebar-track simplebar-horizontal" style={{visibility: "hidden"}}>
                                    <div className="simplebar-scrollbar"
                                         style={{transform: "translate3d(0px, 0px, 0px)", display: "none"}}/>
                                </div>
                                <div className="simplebar-track simplebar-vertical" style={{visibility: "visible"}}>
                                    <div className="simplebar-scrollbar"
                                         style={{
                                             height: "238px",
                                             transform: "translate3d(0px, 0px, 0px)",
                                             display: "block"
                                         }}/>
                                </div>
                            </ol>

                        </div>
                    </div>
                </div>
                <div className="col-md-8 col-xl-8">

                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mt-0">{getIntlMsg(intl, `app.label.form.title`)}</h4>
                            <h6 className="card-subtitle font-14 text-muted">
                            </h6>
                        </div>
                        <div className="card-body">
                            <WizardForm handleSubmite={handleSubmite} {...{specimentcenters, itemPrices, appointments,departure,destination,testType,loading}}
                                        intl={intl}/>
                        </div>
                    </div>
                </div>

            </div>
            <ModalInfo intl={intl}><HomeFaqs/></ModalInfo>
        </React.Fragment>
    );
});

export default React.memo(GlobalHomePage)

