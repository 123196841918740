import React, {useState, useEffect} from "react";
import {Link, NavLink} from "react-router-dom";
import {settings, socialItems} from "../../../constants/options/footer";
import {FormattedMessage} from "react-intl";
import {header} from "../../../constants/options/header";
import {PayPalButton} from "react-paypal-button-v2";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import {useParams, withRouter} from "react-router-dom";
import {ThemeProvider} from '@livechat/ui-kit';


const TopBar = ({handleChangeLanguage, language, history}) => {

    const p = history.location.pathname;
    const path = p.split('/')[1]

    console.log(")))))))<<<<<<<<<<<", path)

    const paymentHandler = (details, data) => {
        console.log(details, data);
    }


    const [state, setState] = useState({
        lang: language,
        label: 'Francais', show: false
    });

    const langs = [
        {value: 'fr', label: 'Francais', logo: "/assets/img/fr.png"},
        {value: 'en', label: 'Anglais', logo: "/assets/img/en.png"},
        {value: 'sp', label: 'Spanish', logo: "/assets/img/en.png"},
    ]

    function setShowM() {
        setState({
            show: true, lang: language,
            label: 'Francais'
        })
    }

    function hide() {
        setState({
            show: false, lang: language,
            label: 'Francais'
        })
    }

    const {lang, label, show}=state;


    const handleChangeLang = e => {

        const val = e
        let item = langs.find(v => v.value === val);
        setState({lang: item.value, label: item.label})
        handleChangeLanguage(val)
    }


    return (<>

        <header id="page-topbar">
            <div class="navbar-header">
                <div class="d-flex">

                    <div class="navbar-brand-box">
                        <a href="index.html" class="logo logo-dark">
                                <span class="logo-sm">
                                       <img src="assets/images/mspp.png" alt="" height="50" data={path}/>
                                    <img src="assets/images/covidlogo.png" alt="" height="30"/>

                                </span>
                            <span class="logo-lg">
                                            <img src="assets/images/mspp.png" alt="" height="50"/>
                                    <img src="assets/images/covidlogo.png" alt="" height="30"/>

                                </span>
                        </a>

                        <a href="index.html" class="logo logo-light">
                                <span class="logo-sm">
                                     <img src="assets/images/mspp.png" alt="" height="50"/>
                                    <img src="assets/images/covidlogo.png" alt="" height="40"/>

                                </span>
                            <span class="logo-lg">
                                   <img src="assets/images/mspp.png" alt="" height="50"/>
                                    <img src="assets/images/covidlogo.png" alt="" height="40"/>

                                </span>
                        </a>
                    </div>

                    <button type="button"
                            class="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
                            data-toggle="collapse" data-target="#topnav-menu-content">
                        <i class="fa fa-fw fa-bars"></i>
                    </button>

                </div>

                <div class="d-flex">


                    <div class="dropdown d-inline-block language-switch">
                        <button type="button" class="btn header-item waves-effect"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img class=""
                                 src={`assets/images/flags/${lang === 'en' ? 'us.jpg' : lang === 'fr' ? 'fren.png' : lang === 'ht' ? 'haiti.jpg' : 'spanish.png'}`}
                                 alt="Header Language" height="16"
                            />
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">


                            <a href="javascript:void(0);" class="dropdown-item notify-item"
                               onClick={() => handleChangeLang('en')}>
                                <img src="assets/images/flags/us.jpg" alt="user-image" class="mr-1" height="12"/>
                                <span class="align-middle"><FormattedMessage id={`app.label.en`}/></span>
                            </a>


                            <a href="javascript:void(0);" class="dropdown-item notify-item"
                               onClick={() => handleChangeLang('fr')}>
                                <img src="assets/images/flags/fren.png" alt="user-image" class="mr-1" height="12"/>
                                <span class="align-middle"><FormattedMessage id={`app.label.fr`}/></span>
                            </a>

                            <a href="javascript:void(0);" class="dropdown-item notify-item"
                               onClick={() => handleChangeLang('sp')}>
                                <img src="assets/images/flags/spanish.png" alt="user-image" class="mr-1" height="12"/>
                                <span class="align-middle"><FormattedMessage id={`app.label.sp`}/></span>
                            </a>
                        </div>
                    </div>


                    {/*<div class="dropdown d-inline-block">*/}
                    {/*<button type="button" class="btn header-item waves-effect" id="page-header-user-dropdown"*/}
                    {/*data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
                    {/*<img class="rounded-circle header-profile-user" src="assets/images/users/avatar-4.jpg"*/}
                    {/*alt="Header Avatar"/>*/}
                    {/*<span class="d-none d-xl-inline-block ml-1 font-weight-medium font-size-15">Marcus</span>*/}
                    {/*<i class="uil-angle-down d-none d-xl-inline-block font-size-15"></i>*/}
                    {/*</button>*/}
                    {/*<div class="dropdown-menu dropdown-menu-right">*/}
                    {/*<a class="dropdown-item" href="#"><i*/}
                    {/*class="uil uil-user-circle font-size-18 align-middle text-muted mr-1"></i> <span*/}
                    {/*class="align-middle">View Profile</span></a>*/}
                    {/*<a class="dropdown-item" href="#"><i*/}
                    {/*class="uil uil-wallet font-size-18 align-middle mr-1 text-muted"></i> <span*/}
                    {/*class="align-middle">My Wallet</span></a>*/}
                    {/*<a class="dropdown-item d-block" href="#"><i*/}
                    {/*class="uil uil-cog font-size-18 align-middle mr-1 text-muted"></i> <span*/}
                    {/*class="align-middle">Settings</span> <span*/}
                    {/*class="badge badge-soft-success badge-pill mt-1 ml-2">03</span></a>*/}
                    {/*<a class="dropdown-item" href="#"><i*/}
                    {/*class="uil uil-lock-alt font-size-18 align-middle mr-1 text-muted"></i> <span*/}
                    {/*class="align-middle">Lock screen</span></a>*/}
                    {/*<a class="dropdown-item" href="#"><i*/}
                    {/*class="uil uil-sign-out-alt font-size-18 align-middle mr-1 text-muted"></i> <span*/}
                    {/*class="align-middle">Sign out</span></a>*/}
                    {/*</div>*/}
                    {/*</div>*/}


                </div>
            </div>
            <div class="container-fluid">
                <div class="topnav">

                    <nav class="navbar navbar-light navbar-expand-lg topnav-menu">

                        <div class="collapse navbar-collapse" id="topnav-menu-content">
                            <ul class="navbar-nav">

                                <Link to={'/'}>
                                    <li class="nav-item">
                                        <a className={p === '/' ? 'nav-link active' : 'nav-link'}>
                                            <i class="uil-home-alt mr-2"></i> <FormattedMessage id={`app.label.home`}/>
                                        </a>
                                    </li>
                                </Link>
                                <Link to={'/request'}>
                                    <li class="nav-item" id="request">
                                        <a className={p === '/request' ? 'nav-link active' : 'nav-link'}>
                                            <i class="uil uil-receipt mr-2"></i> <FormattedMessage
                                            id={`app.label.request`}/>
                                        </a>
                                    </li>
                                </Link>
                                {/*<Link to={'/contact'}>*/}
                                    {/*<li class="nav-item" id="contact">*/}
                                        {/*<a className={p === '/contact' ? 'nav-link active' : 'nav-link'}>*/}
                                            {/*<i class="fa fa-mail-bulk mr-2"></i> <FormattedMessage*/}
                                            {/*id={`app.label.contact`}/>*/}
                                        {/*</a>*/}
                                    {/*</li>*/}
                                {/*</Link>*/}
                                {/*<Link to={'/faqs'}>*/}
                                {/*<li class="nav-item">*/}
                                {/*<a className={p === '/actuality' ? 'nav-link active' : 'nav-link'}>*/}
                                {/*<i class="uil-layers mr-2"></i> <FormattedMessage id={`app.label.faqs`}/>*/}
                                {/*</a>*/}
                                {/*</li>*/}
                                {/*</Link>*/}


                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </header>


        </>
    );
};

export default React.memo(withRouter(TopBar))
