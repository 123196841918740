import React from "react";
import * as validator from '../../../../constants/utils/validator'
import {getIntlMsg} from "../../../../constants/utils";
import {injectIntl, FormattedMessage} from "react-intl"
import moment from 'moment';
import {format} from 'date-fns'
import swal from '@sweetalert/with-react'

export const validate = values => {
    const errors = {}

    //NOTE when calling validateString the params are: (label, value, min, max, required=false)
    errors.phone = validator.validateString('phone', values.phone, 3, 50, true)
    errors.dob = validator.validateString('dob', values.dob, 10, 10, true)
    errors.firstname = validator.validateString('firstname', values.firstname, 3, 30, true)
    errors.lastname = validator.validateString('lastname', values.lastname, 3, 30, true)
    errors.subject = validator.validateString('subject', values.subject, 3, 30, true)
    errors.message = validator.validateString('message', values.message, 3, 30, true)

    return errors
}

export const minDateNormalize = (val, prevVal) => {
    // Prevent non-digit characters being entered
    if (isNaN(parseInt(val[val.length - 1], 10))) {
        return val.slice(0, -1);
    }

    // When user is deleting, this prevents immediate re-addition of '/' when it's deleted
    if (prevVal && (prevVal.length >= val.length)) {
        return val;
    }

    // Add / at appropriate sections of the input
    if (val.length === 2) {
        val += '/';
    }
    if (val.length > 3 && val.length === 5) {
        val += '/';
    }
    // if (val.length >8 ) {
    //     val += '';
    // }

    // Prevent characters being entered after Dob is full
    if (val.length >= 10) {
        return val.slice(0, 10);
    }

    return val
};


const reformatDateString = (s) => {
    var b = s.split(/\D/);
    return b.reverse().join('-');
}

function convertDate(inputFormat) {
    function pad(s) {
        return (s < 10) ? '0' + s : s;
    }

    var d = new Date(inputFormat)
    return [pad(d.getMonth() + 1), pad(d.getDate()), d.getFullYear()].join('/')
}


export const birthDate = (intl) => value => {

    if (value) {
        var day = value.split("/")[0];
        var month = value.split("/")[1];
        var year = value.split("/")[2];

        const d = convertDate(`${month}/${day}/${year}`);
        const gha = moment(d).format('YYYY/MM/DD')

        if (gha !== "Invalid date") {
            if (value && (new Date(gha) < new Date('1900'))) {
                return getIntlMsg(intl, 'app.label.lowerOld')
            } else if (value && (new Date(gha) > new Date())) {
                return getIntlMsg(intl, 'app.label.greadOld')
            }
        } else {
            return getIntlMsg(intl, 'app.label.invalidDate')
        }


    }
};

export const renderError = ({meta: {touched, error}}) =>
    touched && error ?
        <small className="has-error" style={{fontSize: "12px", color: 'red'}}>
            <FormattedMessage id={`app.label.${error}`} defaultMessage={error}/>
        </small>
        : false

const ageDayDif = (birthdate) => {
    var day = '';
    if (birthdate != null) {
        day = (new Date()).getUTCDate() + (new Date(birthdate)).getUTCDate();
        if (day < 0)
            day += 30;
    }
    return day;
}


const departureInfo = intl => {

    swal({
        icon: "info",
        text: ``,
        button: "Ok",
        content: (
            <div>
                <h5>{getIntlMsg(intl, 'app.label.departureFailled')}</h5>
                <h5><b>{getIntlMsg(intl, 'app.label.departureFailled1')}</b></h5>
                <a href="tel:+50948916120" style={{fontSize: '20px'}}><i
                    className="fa fa-phone">{getIntlMsg(intl, 'app.label.departureFailled2')}</i></a>
            </div>
        )
    }).then((value) => {

    });
}


export const departureDate = (intl) => value => {

    if (value) {
        var day = value.split("/")[0];
        var month = value.split("/")[1];
        var year = value.split("/")[2];

        const d = convertDate(`${month}-${day}-${year}`);
        const d2 = new Date(`${month}/${day}/${year}`)
        const d1 = new Date()
        const gha = moment(d2).format('YYYY-MM-DD')

        var diff = d2.getTime() - d1.getTime();

        var daydiff = diff / (1000 * 60 * 60 * 24);

        var res = Math.round(daydiff);

        if (gha !== "Invalid date") {
            if (value && (new Date(``) < new Date())) {
                return getIntlMsg(intl, 'app.label.departureErrordate')
            }
            else {
                if (res >= 2) {
                    return undefined
                } else {
                    if (year.length === 4)
                        return departureInfo(intl)
                    else return undefined
                    // return getIntlMsg(intl, 'app.label.departureFailled')
                }
            }
        } else {
            return getIntlMsg(intl, 'app.label.invalidDate')
        }
    }
};

export const required = value => value ? undefined : 'Required';


export const maxLength = max => value =>
    value && value.length > max ? `Must be ${max} characters or less` : undefined


export const valNif = (max, intl) => value =>
    value && value.length < max ? getIntlMsg(intl, 'app.label.invalid') : undefined

export const valDob = (max, intl) => value =>
    value && value.length < max ? getIntlMsg(intl, 'app.label.invalid') : undefined


export const valCin = (max, intl) => value =>
    value && value.length < max ? getIntlMsg(intl, 'app.label.invalid') : undefined

export const maxLengthMob = (max, intl) => value =>
    value && value >= max ? getIntlMsg(intl, 'app.label.monthError') : undefined

export const maxLength15 = maxLength(15)

export const number = value => value && isNaN(Number(value)) ? 'Must be a number' : undefined
export const minValue = min => value =>
    value && value < min ? `Must be at least ${min}` : undefined
export const minValue18 = minValue(18)
export const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
        'Invalid email address' : undefined
export const tooOld = value =>
    value && value > 65 ? 'You might be too old for this' : undefined
export const aol = value =>
    value && /.+@aol\.com/.test(value) ?
        'Really? You still use AOL for your email?' : undefined

export const normalizeNif = (value, previousValue) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (!previousValue || value.length > previousValue.length) {
        // typing forward
        if (onlyNums.length === 3) {
            return onlyNums + '-'
        }
        if (onlyNums.length === 6) {
            return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3) + '-'
        }
        if (onlyNums.length === 9) {
            return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 6) + '-' + onlyNums.slice(6) + '-'
        }
    }

    if (onlyNums.length <= 3) {
        return onlyNums
    }
    if (onlyNums.length <= 6) {
        return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3)
    }
    if (onlyNums.length <= 9) {
        return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 6) + '-' + onlyNums.slice(6)
    }

    return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 6) + '-' + onlyNums.slice(6, 9) + '-' + onlyNums.slice(9, 10)
}


export const normalizePhone = (value, previousValue) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (!previousValue || value.length > previousValue.length) {
        // typing forward
        if (onlyNums.length === 4) {
            return onlyNums + '-'
        }
        if (onlyNums.length === 8) {
            return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4, 8)
        }

    }

    if (onlyNums.length <= 4) {
        return onlyNums
    }
    if (onlyNums.length <= 8) {
        return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4, 8)
    }

    return onlyNums.slice(0, 4) + '-' + onlyNums.slice(4, 8);
}


export const normalizeCin = (value, previousValue) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')

    if (onlyNums.length <= 10) {
        return onlyNums.slice(0, 10)
    } else {


        if (!previousValue || value.length > previousValue.length) {
            // typing forward
            if (onlyNums.length === 2) {
                return onlyNums + '-'
            }
            if (onlyNums.length === 4) {
                return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2) + '-'
            }
            if (onlyNums.length === 6) {
                return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2, 4) + '-' + onlyNums.slice(4) + '-'
            }
            if (onlyNums.length === 10) {
                return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2, 4) + '-' + onlyNums.slice(4, 6) + '-' + onlyNums.slice(6, 10) + '-'
            }
            if (onlyNums.length === 12) {
                return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2, 4) + '-' + onlyNums.slice(4, 6) + '-' + onlyNums.slice(6, 10) + '-' + onlyNums.slice(10, 12) + '-'
            }
        }

        if (onlyNums.length <= 2) {
            return onlyNums
        }
        if (onlyNums.length <= 4) {
            return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2)
        }
        if (onlyNums.length <= 6) {
            return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2, 4) + '-' + onlyNums.slice(4)
        }
        if (onlyNums.length <= 10) {
            return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2, 4) + '-' + onlyNums.slice(4, 6) + '-' + onlyNums.slice(6, 10)
        }
        if (onlyNums.length <= 12) {
            return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2, 4) + '-' + onlyNums.slice(4, 6) + '-' + onlyNums.slice(6, 10) + '-' + onlyNums.slice(10, 12)
        }

        return onlyNums.slice(0, 2) + '-' + onlyNums.slice(2, 4) + '-' + onlyNums.slice(4, 6) + '-' + onlyNums.slice(6, 10) + '-' + onlyNums.slice(10, 12) + '-' + onlyNums.slice(12, 17)
    }
}
