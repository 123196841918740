import {combineReducers} from "redux";
import {reducer as formReducer} from 'redux-form'
import {connectRouter} from "connected-react-router";
import coreReducer from "./common/_coreReducer";
import _proxyReducer from "./common/_proxyReducer";
import settingReducer from "./common/_settingReducer";
import pagesReducer from "./pagesReducer";
import itemPriceReducer from "./setting/itemPriceReducer";

const createRootReducer = (history) =>
    combineReducers({
        form: formReducer,
        router: connectRouter(history),
        keycloak: (keycloak = {}) => keycloak,
        coreReducer,
        proxyReducer: _proxyReducer,
        settingReducer,
        pagesReducer,
        itemPriceReducer,
    });
export default createRootReducer;