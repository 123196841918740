import React, {useContext} from "react";
import {Route} from "react-router";
import {Redirect} from "react-router-dom";
import {hasEntryAccess} from "../../../constants/utils/security";
import {GlobalContext} from "../../index";


const PrivateRoute = ({component: Component, ...rest}) => {

    const {keycloak} = useContext(GlobalContext);
    const {location: {pathname}} = {...rest};
    // const roles = userSession(keycloak).roles;
    const roles = ['Administrator'];





    const customPath = pathname.substring(pathname.lastIndexOf("/") + 1, pathname.length);
    const entry = pathname === '/' ? 'admin' : customPath.replace(/\//g, '');
    return (
        (roles.length === 0) ?
            <Redirect to="/"/>
            // : keycloak.isTokenExpired() ? keycloak.logout()
            :2 === 3 ? keycloak.logout()
            : hasEntryAccess(roles, entry) ? <Route {...rest} render={(props) => (<Component {...props}/>)}/>
                : <Redirect to="/"/>

    )
};

export default PrivateRoute;