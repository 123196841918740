/**
 * Created by louidorpc on 1/26/21.
 */
import * as React from "react";
import useDocumentTitle from "../../common/hooks/useDocumentTitle";
import {getIntlMsg} from "../../../constants/utils";
import {injectIntl} from "react-intl";
import {Terms} from '../../../constants/options/data';
import actions from "../../../thunks/common/coreThunks";
import {useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {RequestModel, Applicant} from '../../../constants/models'
import swal from 'sweetalert'
import {useParams, withRouter} from "react-router-dom";
import {useEffect} from "react";
import ReactPlayer from 'react-player/lazy'
import {Link, NavLink} from "react-router-dom";
const HomePage = injectIntl(({intl}) => {
    const {module} = useParams();
    useDocumentTitle(getIntlMsg(intl, `app.label.home`));
    const dispatch = useDispatch();


    const criteria = {options: ['specimencenters']}

    useEffect(() => {
        dispatch(actions.loadItems(criteria));
    }, [dispatch]);


    const handleSubmiteSucce = (values) => {

        swal({
            title: `${values.firstName ? values.lastName : null}, ${getIntlMsg(intl, 'app.label.confirm')}`,
            text: "",
            icon: "success",
            button: "Next",
        }).then((value) => {
            document.location = "/";
        });
    }

    const [formData, setFormData] = useState({});

    const handleSubmite = values => {
        const ent = new RequestModel(values)

        const entity = {
            options: ['REQUESTS'], action: 'save', intl: intl, item: ent
        }
        setFormData(values);
        dispatch(actions.saveOrUpdateItem(entity));
    }

    const {loading, item, specimentcenters} = useSelector(state => ({
        loading: state.pagesReducer.requests.loadingItem,
        item: state.pagesReducer.requests.item,
        specimentcenters: state.pagesReducer.specimencenters.items,
    }));

    if (item && item.id != null) handleSubmiteSucce(formData)

    return (
        <React.Fragment>
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">


                            <div class="row mt-5">
                                <div class="col-xl-3 col-sm-5 mx-auto">
                                    <div>
                                        <img src="assets/images/faqs-img.png" alt="" class="img-fluid mx-auto d-block"/>
                                    </div>
                                </div>

                                <div class="col-xl-8">
                                    <div id="faqs-accordion" class="custom-accordion mt-5 mt-xl-0">
                                        <div class="card border shadow-none">
                                            <a href="#faqs-gen-ques-collapse" class="text-dark" data-toggle="collapse"
                                               aria-expanded="true" aria-controls="faqs-gen-ques-collapse">
                                                <div class="bg-light p-3">

                                                    <div class="media align-items-center">
                                                        <div class="mr-3">
                                                            <div class="avatar-xs">
                                                                <div class="avatar-title rounded-circle font-size-22">
                                                                    <i class="fa fa-calendar-check"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="media-body overflow-hidden">
                                                            <h5 class="font-size-16 mb-1">{getIntlMsg(intl, 'app.label.t1')}</h5>
                                                            <p class="text-muted text-truncate mb-0"></p>
                                                        </div>
                                                        <i class="fa fa-arrow-up accor-down-icon font-size-16"></i>
                                                    </div>

                                                </div>
                                            </a>

                                            <div id="faqs-gen-ques-collapse" class="collapse show"
                                                 data-parent="#faqs-accordion">
                                                <div class="p-4">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div>
                                                                <div class="media mt-4">
                                                                    <div class="avatar-xs mr-3">
                                                                        <div
                                                                            class="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                                                            <i class="fa fa-info"></i>
                                                                        </div>
                                                                    </div>

                                                                    <div class="media-body">
                                                                        {/*<h5 class="font-size-16 mt-1">{getIntlMsg(intl, 'app.label.q4')}</h5>*/}
                                                                        <div dangerouslySetInnerHTML={{
                                                                            __html: intl.formatMessage(
                                                                                {id: 'app.label.info1'},
                                                                                {value: '<strong>STRONG</strong>'}
                                                                            )
                                                                        }} style={{textAlign: 'justify'}}></div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card border shadow-none">
                                            <a href="#faqs-privacy-policy-collapse1" class="text-dark"
                                               data-toggle="collapse" aria-expanded="true"
                                               aria-controls="faqs-privacy-policy-collapse">
                                                <div class="bg-light p-3">

                                                    <div class="media align-items-center">
                                                        <div class="mr-3">
                                                            <div class="avatar-xs">
                                                                <div class="avatar-title rounded-circle font-size-22">
                                                                    <i class="fa fa-money-bill"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="media-body overflow-hidden">
                                                            <h5 class="font-size-16 mb-1">{getIntlMsg(intl, 'app.label.t2')}</h5>
                                                            <p class="text-muted text-truncate mb-0"></p>
                                                        </div>
                                                        <i class="fa fa-arrow-up accor-down-icon font-size-16"></i>
                                                    </div>

                                                </div>
                                            </a>

                                            <div id="faqs-privacy-policy-collapse1" class="collapse show"
                                                 data-parent="#faqs-accordion">
                                                <div class="p-4">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div>
                                                                <div class="media mt-4">
                                                                    <div class="avatar-xs mr-3">
                                                                        <div
                                                                            class="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                                                            <i class="fa fa-info"></i>
                                                                        </div>
                                                                    </div>

                                                                    <div class="media-body">
                                                                        {/*<h5 class="font-size-16 mt-1">{getIntlMsg(intl, 'app.label.q2')}</h5>*/}

                                                                        <div dangerouslySetInnerHTML={{
                                                                            __html: intl.formatMessage({id: 'app.label.info2'}, {value: '<strong>STRONG</strong>'})
                                                                        }} style={{}}></div>

                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card border shadow-none">
                                            <a href="#faqs-privacy-policy-collapse2" class="text-dark collapsed"
                                               data-toggle="collapse" aria-expanded="false"
                                               aria-controls="faqs-privacy-policy-collapse">
                                                <div class="bg-light p-3">

                                                    <div class="media align-items-center">
                                                        <div class="mr-3">
                                                            <div class="avatar-xs">
                                                                <div class="avatar-title rounded-circle font-size-22">
                                                                    <i class="fa fa-syringe"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="media-body overflow-hidden">
                                                            <h5 class="font-size-16 mb-1">{getIntlMsg(intl, 'app.label.t3')}</h5>
                                                            <p class="text-muted text-truncate mb-0"></p>
                                                        </div>
                                                        <i class="fa fa-arrow-up accor-down-icon font-size-16"></i>
                                                    </div>

                                                </div>
                                            </a>

                                            <div id="faqs-privacy-policy-collapse2" class="collapse"
                                                 data-parent="#faqs-accordion">
                                                <div class="p-4">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div>
                                                                <div class="media mt-4">
                                                                    <div class="avatar-xs mr-3">
                                                                        <div
                                                                            class="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                                                            <i class="fa fa-info"></i>
                                                                        </div>
                                                                    </div>

                                                                    <div class="media-body">
                                                                        {/*<h5 class="font-size-16 mt-1">{getIntlMsg(intl, 'app.label.q3')}</h5>*/}
                                                                        <div dangerouslySetInnerHTML={{
                                                                            __html: intl.formatMessage(
                                                                                {id: 'app.label.info3'},
                                                                                {value: '<strong>STRONG</strong>'}
                                                                            )
                                                                        }} style={{textAlign: 'justify'}}></div>

                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card border shadow-none">
                                            <a href="#faqs-pricing-plans-collapse3" class="text-dark collapsed"
                                               data-toggle="collapse"
                                               aria-expanded="false" aria-controls="faqs-pricing-plans-collapse">
                                                <div class="bg-light p-3">

                                                    <div class="media align-items-center">
                                                        <div class="mr-3">
                                                            <div class="avatar-xs">
                                                                <div class="avatar-title rounded-circle font-size-22">
                                                                    <i class="fa fa-poll"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="media-body overflow-hidden">
                                                            <h5 class="font-size-16 mb-1">{getIntlMsg(intl, 'app.label.t4')}</h5>
                                                            <p class="text-muted text-truncate mb-0"></p>
                                                        </div>
                                                        <i class="fa fa-arrow-up accor-down-icon font-size-16"></i>
                                                    </div>

                                                </div>
                                            </a>

                                            <div id="faqs-pricing-plans-collapse3" class="collapse"
                                                 data-parent="#faqs-accordion">
                                                <div class="p-4">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div>
                                                                <div class="media mt-4">
                                                                    <div class="avatar-xs mr-3">
                                                                        <div
                                                                            class="avatar-title rounded-circle bg-soft-primary text-primary font-size-22">
                                                                            <i class="fa fa-info"></i>
                                                                        </div>
                                                                    </div>

                                                                    <div class="media-body">
                                                                        {/*<h5 class="font-size-16 mt-1">{getIntlMsg(intl, 'app.label.q1')}</h5>*/}
                                                                        <div dangerouslySetInnerHTML={{
                                                                            __html: intl.formatMessage(
                                                                                {id: 'app.label.info4'},
                                                                                {value: '<strong>STRONG</strong>'}
                                                                            )
                                                                        }} style={{textAlign: 'justify'}}></div>

                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/*<div className="row">*/}

            {/*<div class="col-xl-6">*/}
            {/*<div class="card">*/}
            {/*<div class="card-body">*/}

            {/*<h4 class="card-title">Zanmi Lasante Covid19 Gestion</h4>*/}
            {/*<div style={{margin: 'auto', width:'100%'}}>*/}
            {/*<div class="embed-responsive embed-responsive-16by9">*/}
            {/*<ReactPlayer url={[*/}
            {/*{src: '/assets/1.mp4', type: 'video/mp4'}*/}
            {/*]}*/}
            {/*playing={true}*/}
            {/*muted="true"*/}
            {/*className="embed-responsive-item"*/}
            {/*width="100%"*/}
            {/*height="100%"*/}
            {/*controls="true"*/}


            {/*/>*/}
            {/*/!*<video width="320" height="240" className="embed-responsive-item"  autoplay muted>*!/*/}
            {/*/!*<source src="/assets/1.mp4" type="video/mp4" />*!/*/}
            {/*/!*</video>*!/*/}

            {/*</div>*/}
            {/*</div>*/}
            {/*</div>*/}
            {/*</div>*/}
            {/*</div>*/}

            {/*<div className="col-md-6">*/}
            {/*<div class="row">*/}
            {/*<div class="col-md-6 col-xl-4">*/}
            {/*<div class="card">*/}
            {/*<div class="card-body">*/}
            {/*<div class="float-right mt-2">*/}
            {/*<div id="total-revenue-chart"></div>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*<h4 class="mb-1 mt-1"><span data-plugin="counterup" className="text-info"> 10,907</span>*/}
            {/*</h4>*/}
            {/*<p class="text-muted mb-0">Cas confirmé en Haïti</p>*/}
            {/*</div>*/}
            {/*<p class="text-muted mt-3 mb-0"><span class="text-success mr-1"><i*/}
            {/*class=" ml-1"></i> 23.6%</span> taux de positivit*/}
            {/*</p>*/}
            {/*</div>*/}
            {/*</div>*/}
            {/*</div>*/}

            {/*<div class="col-md-6 col-xl-4">*/}
            {/*<div class="card">*/}
            {/*<div class="card-body">*/}
            {/*<div class="float-right mt-2">*/}
            {/*<div id="total-revenue-chart"></div>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*<h4 class="mb-1 mt-1"><span data-plugin="counterup"*/}
            {/*className="text-danger"> 240</span></h4>*/}
            {/*<p class="text-muted mb-0">Morts en Haïti</p>*/}
            {/*</div>*/}
            {/*<p class="text-muted mt-3 mb-0"><span class="text-success mr-1"><i*/}
            {/*class=" ml-1"></i> 2.20%</span> Taux de mortalité*/}
            {/*</p>*/}
            {/*</div>*/}
            {/*</div>*/}
            {/*</div>*/}


            {/*<div class="col-md-6 col-xl-4">*/}
            {/*<div class="card">*/}
            {/*<div class="card-body">*/}
            {/*<div class="float-right mt-2">*/}
            {/*<div id="total-revenue-chart"></div>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*<h4 class="mb-1 mt-1"><span data-plugin="counterup" className="text-success">  8,956</span>*/}
            {/*</h4>*/}
            {/*<p class="text-muted mb-0"> Cas récupérés en Haïti</p>*/}
            {/*</div>*/}
            {/*<p class="text-muted mt-3 mb-0"><span class="text-success mr-1"><i*/}
            {/*class=" ml-1"></i> </span>*/}
            {/*</p>*/}
            {/*</div>*/}
            {/*</div>*/}
            {/*</div>*/}

            {/*<div class="col-md-6 col-xl-4">*/}
            {/*<div class="card">*/}
            {/*<div class="card-body">*/}
            {/*<div class="float-right mt-2">*/}
            {/*<div id="total-revenue-chart"></div>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*<h4 class="mb-1 mt-1"><span data-plugin="counterup" className="text-warning"> 46,313</span>*/}
            {/*</h4>*/}
            {/*<p class="text-muted mb-0"> Cas suspects en Haïti </p>*/}
            {/*</div>*/}
            {/*<p class="text-muted mt-3 mb-0"><span class="text-success mr-1"><i*/}
            {/*class=" ml-1"></i> </span>*/}
            {/*</p>*/}
            {/*</div>*/}
            {/*</div>*/}
            {/*</div>*/}

            {/*<div class="col-md-6 col-xl-4">*/}
            {/*<div class="card">*/}
            {/*<div class="card-body">*/}
            {/*<div class="float-right mt-2">*/}
            {/*<div id="total-revenue-chart"></div>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*<h4 class="mb-1 mt-1"><span data-plugin="counterup"> 904</span></h4>*/}
            {/*<p class="text-muted mb-0"> Cas dans le réseau de ZL </p>*/}
            {/*</div>*/}
            {/*<p class="text-muted mt-3 mb-0"><span class="text-success mr-1"><i*/}
            {/*class=" ml-1"></i> </span>*/}
            {/*</p>*/}
            {/*</div>*/}
            {/*</div>*/}
            {/*</div>*/}

            {/*<div class="col-md-6 col-xl-4">*/}
            {/*<div class="card">*/}
            {/*<div class="card-body">*/}
            {/*<div class="float-right mt-2">*/}
            {/*<div id="total-revenue-chart"></div>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*<h4 class="mb-1 mt-1"><span data-plugin="counterup"> 88</span></h4>*/}
            {/*<p class="text-muted mb-0"> Morts dans le réseau de ZL </p>*/}
            {/*</div>*/}
            {/*<p class="text-muted mt-3 mb-0"><span class="text-success mr-1"><i*/}
            {/*class=" ml-1"></i> </span>*/}
            {/*</p>*/}
            {/*</div>*/}
            {/*</div>*/}
            {/*</div>*/}


            {/*<div class="col-md-6 col-xl-4">*/}
            {/*<div class="card">*/}
            {/*<div class="card-body">*/}
            {/*<div class="float-right mt-2">*/}
            {/*<div id="total-revenue-chart"></div>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*<h4 class="mb-1 mt-1"><span data-plugin="counterup"> 482</span></h4>*/}
            {/*<p class="text-muted mb-0"> Cas récupérés dans le réseau de ZL </p>*/}
            {/*</div>*/}
            {/*<p class="text-muted mt-3 mb-0"><span class="text-success mr-1"><i*/}
            {/*class=" ml-1"></i> </span>*/}
            {/*</p>*/}
            {/*</div>*/}
            {/*</div>*/}
            {/*</div>*/}

            {/*<div class="col-md-6 col-xl-4">*/}
            {/*<div class="card">*/}
            {/*<div class="card-body">*/}
            {/*<div class="float-right mt-2">*/}
            {/*<div id="total-revenue-chart"></div>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*<h4 class="mb-1 mt-1"><span data-plugin="counterup"> 19,703</span></h4>*/}
            {/*<p class="text-muted mb-0"> Cas suspects dans le réseau de ZL </p>*/}
            {/*</div>*/}
            {/*<p class="text-muted mt-3 mb-0"><span class="text-success mr-1"><i*/}
            {/*class=" ml-1"></i> </span>*/}
            {/*</p>*/}
            {/*</div>*/}
            {/*</div>*/}
            {/*</div>*/}

            {/*<div class="col-md-6 col-xl-4">*/}
            {/*<div class="card">*/}
            {/*<div class="card-body">*/}
            {/*<div class="float-right mt-2">*/}
            {/*<div id="total-revenue-chart"></div>*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*<h4 class="mb-1 mt-1"><span data-plugin="counterup"> 3,369</span></h4>*/}
            {/*<p class="text-muted mb-0"> Cas positifs dans le réseau de ZL </p>*/}
            {/*</div>*/}
            {/*<p class="text-muted mt-3 mb-0"><span class="text-success mr-1"><i*/}
            {/*class=" ml-1"></i> </span>*/}
            {/*</p>*/}
            {/*</div>*/}
            {/*</div>*/}
            {/*</div>*/}


            {/*</div>*/}
            {/*</div>*/}


            {/*</div>*/}
        </React.Fragment>
    );
});

export default React.memo(HomePage)

